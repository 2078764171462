@import "../utility.module.scss";
.news {
  @include sp {
    margin: 30px auto 0;
  }
  max-width: 960px;
  margin: 100px auto 0;
  padding: 0;
}

.news_new{
  @include sp {
    margin: 30px auto 0;
  }
  max-width: 960px;
  margin: 56px auto 0;
  padding: 0;
}

.news_title {
  text-align: center;
  padding-bottom: 16px;
  border-bottom: 1px solid var(--main-color);
  color: #000;
  text-align: center;
  font-family: Noto Sans JP;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  @include sp {
    font-size: 16px;
  }
  @include pc {
    font-size: 20px;
    margin-bottom: 32px;
  }
}

.news_title_new{
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 16px;
  padding-bottom: 24px;
  border-bottom: 1px solid var(--main-color);
  h3{
    color: #222;
    font-family: "Noto Sans JP";
    @include pc{
      font-size: 36px;
    }
    @include sp{
      font-size: 24px;
    }
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
  }
}
.link_to_all{
  margin-top: 24px;
  display: flex;
  align-items: center;
  justify-content: right;
  h3{
    color: #222;
    text-align: right;
    font-family: "Noto Sans JP";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    text-decoration-line: underline;
    margin-right: 8px;
  }
}
