// MUIの既存のCSSの上書き
.content {
  padding: 0 !important;
  margin: 0 !important;
  &:first-child {
    padding-top: 0 !important;
  }
  iframe{
    width: 560px;
    height: 315px;
  }
}
.dialog {
  z-index: 2;
}
