@import "../utility.module.scss";

.article_link {
  @include sp {
    padding: 10px 0;
  }
  border-bottom: 1px solid #dddddd;
  padding: 25px 0;
  position: relative;

  &:last-child {
    border-bottom: none;
  }
}

.article_link_time {
  @include sp {
    display: block;
    width: 100%;
    text-align: left;
  }
  @include pc {
    display: inline-block;
    width: 15%;
    font-size: 14px;
    text-align: center;
    color: #888888;
    border-right: 1px solid #dddddd;
  }
}

.article_link_article_title {
  font-size: 14px;
  font-weight: bold;
  @include sp {
    display: block;
    width: 100%;
    padding: 0 50px 0 0;
    line-height: 1.8;
  }
  @include pc {
    display: inline-block;
    width: 80%;
    padding-left: 22px;
  }
}

.article_time_like_container{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.article_link_new{
  padding: 16px 0;
  border-bottom: 1px solid #EBEBEB;
  a{
    color: #222;
    font-family: "Noto Sans JP";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 160%;
  }
  .time_new{
    color: #999;
    font-family: "Noto Sans JP";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
  }
}
.article_actionbox {
  text-align: center;
  display: flex;
}