@import "../../utility.module.scss";

.form {
  @include sp {
    width: 100%;
  }

  margin-bottom: 0;
  font-size: 14px;
  padding: 0 0 0 16px;
  width: 300px;
  height: 35px;
  font-size: 14px;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid #dddddd;
}

.disable {
  @extend .form;
  pointer-events: none;
  background: transparent;
  box-shadow: none;
  border-radius: unset;
  font-size: 14px;
  border: 1px solid #dddddd;
}
