@import "../../utility.module.scss";

.form {
  @include sp {
    width: 100%;
    height: 77px;
  }
  border: 1px solid #dddddd;
  width: 300px;
  height: 56px;
  padding: 12px 16px;
  background-color: #fff;
  box-sizing: border-box;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
}

.disable {
  @extend .form;
  pointer-events: none;
  background: transparent;
  box-shadow: none;
  border-radius: unset;
}
