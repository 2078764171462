@import "../utility.module.scss";

.step_container {
  position: relative;
  width: 100%;
}

.step_content {
  position: absolute;
  top: 0;
  left: 0;
}

.button {
  width: 320px;
  & * {
    font-size: 14px;
    font-weight: bold;
  }
}

.button_disable {
  background-color: #dddddd;
  border: 1px solid #dddddd;
  display: block;
  text-decoration: none;
  text-align: center;
  position: relative;
  margin: 0 auto;
  padding: 0;
  -moz-border-radius: 60px;
  -webkit-border-radius: 60px;
  border-radius: 60px;
  & * {
    color: #ffffff;
    font-size: 14px;
    font-weight: bold;
    text-decoration: none;
    display: inline-block;
    padding: 15px 10px;
    width: 100%;
  }
}

.event_tiile {
  background-color: #f6f6f6;
  border-radius: 5px;
  font-size: 14px;
  text-align: center;
  padding: 10px;
  margin: 12% 0 0;
}

.title {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
}

.text {
  font-size: 14px;
  font-weight: bold;
}

.sub_text {
  font-size: 12px;
}

.attention {
  color: #ef2628;
  margin-left: 5px;
  font-size: 12px;
}

.first_step_content path:nth-child(1){
  fill: var(--main-color);
}
