@import "../utility.module.scss";

.category_card_list {
  margin-top: 64px;
  margin: 100px auto 0;
  max-width: 960px;
  @include sp {
    margin: 64px 0 0;
    width: 100%;
  }
}

.category_card_list_title {
  text-align: center;
  padding-bottom: 9px;
  border-bottom: 1px solid var(--main-color);
  margin-bottom: 16px;

  color: #000;
  text-align: center;
  font-family: Noto Sans JP;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  @include sp {
    font-size: 16px;
  }
  @include pc {
    font-size: 20px;
  }
}

.category_card_list_wrap {
  display: flex;
  flex-wrap: wrap;
  gap: unquote("min(16px, 1.66vw)");
  @include sp {
    height: auto !important;
    gap: 4.2vw 4vw;
  }
  &_new {
    gap: unquote("min(8px, 1.66vw)") !important;
  }
}

.category_card_list_title_new {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 16px;
  padding-bottom: 24px;
  h3 {
    color: #222;
    font-family: "Noto Sans JP";
    @include pc {
      font-size: 36px;
    }
    @include sp {
      font-size: 24px;
    }
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
  }
}

.information_list_newest {
  @include pc {
    display: grid;
    grid-template-columns: auto auto auto;
    justify-content: flex-start;
    gap: 15px;
  }
}

.information_list_block {
  min-height: 350px;
  @include sp {
    margin: 0 -4vw;
  }
}

.information_list_container {
  max-width: 960px;
  margin: 0 auto;
  padding-top: 24px;
  @include sp {
    min-height: 270px;
  }
  @include pc {
    min-height: 160px;
  }
}

.information_card {
  border-radius: 16px;
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.2);
  background-color: #fff;
  overflow: hidden;
  @include pc {
    width: 228px;
  }
  @include sp {
    margin: 16px 0;
    min-height: 118px;
    padding: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .information_image_block {
      height: 78px;
      width: 100%;
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        height: 78px;
        width: 100%;
        object-fit: contain;
      }
    }
  }
}

.information_card_newest:hover,
.information_card:hover {
  cursor: pointer;
  opacity: 0.6;
}

.information_card_newest {
  overflow: hidden;
  @include sp {
    margin: 0 auto;
    width: calc(100% - 8vw);
  }
  width: 310px;
  background-color: #fff;
  border-radius: 16px;
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.2);
}

.information_image_container {
  width: 100%;
  background-color: #f4f5f7;
  padding: 0 10px;
  position: relative;
}

.information_image_block {
  width: 100%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.information_newest_text {
  position: absolute;
  left: 18px;
  bottom: -10px;
  width: 48px;
  height: 24px;
  border-radius: 2px;
  background-color: var(--main-color);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  h4 {
    color: #fff;
    font-family: "Noto Sans JP";
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
  }
}

.information_image {
  height: 174px;
  width: 100%;
  object-fit: contain;
  &_new {
    height: 128px;
    width: 100%;
    object-fit: contain;
  }
}

.information_image_container_older {
  background-color: #f4f5f7;
  position: relative;
  @include pc {
    padding: 0 10px;
    width: 100%;
  }
  @include sp {
    padding: 0 4px;
    width: 45%;
    border-radius: 12px;
  }
}

.information_content {
  padding: 10px 16px 16px 16px;
  h3 {
    color: #222;
    font-family: "Noto Sans JP";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 160%;
  }
  height: 107px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.information_content_older {
  h3 {
    color: #222;
    font-family: "Noto Sans JP";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 160%;
  }
  @include pc {
    padding: 16px;
    height: 132px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  @include sp {
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 50%;
    padding: 0;
  }
}

.information_time_like_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.information_time {
  color: #999;
  font-family: "Noto Sans JP";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
}

.information_notfound {
  text-align: center;
}

.information_actionbox {
  text-align: center;
  display: flex;
}

.information_older {
  @include pc {
    margin-top: 24px;
    display: grid;
    grid-template-columns: auto auto auto auto;
    justify-content: flex-start;
    gap: 16px;
  }
  @include sp {
    margin: 16px auto;
    width: calc(100% - 8vw);
  }
}

.button_link {
  display: block;
  border-radius: 90px;
  border: 1px solid #ccc;
  text-align: center;
  margin-top: 8px;
  position: relative;
  padding: 17px 0;
  @include pc {
    width: 320px;
    margin: 24px auto 0 auto;
  }
  @include sp {
    width: calc(100% - 8vw);
    margin: 8px auto 0 auto;
  }
  svg {
    position: absolute;
    top: calc(50% - 8px);
    right: 5px;
  }
  h3 {
    color: #222;
    text-align: center;
    font-family: "Noto Sans JP";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
  }
}

.button_link:hover {
  opacity: 0.6;
}

.information_title {
  max-height: 50px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.information_title_old {
  max-height: 67px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.category_card_list_wrap_new {
  display: flex;
  width: auto;
  white-space: nowrap;
  overflow-x: scroll;
  flex-wrap: nowrap;
}

.category_card_list_wrap_new::-webkit-scrollbar {
  display: none;
}

.playButton {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.information_image_block {
  position: relative;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  z-index: 1;
}

.playButtonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.categorySwiperContainer {
  width: 100%;
  max-width: 960px;
  margin: 0 auto;
  overflow: hidden;
}

.categorySwiper {
  padding-left: 20px; // PC用
}

.categorySlide {
  max-width: 200px;
}


@include sp {
  .categorySwiper {
    padding-left: 10px; // モバイル用
  }
}
