@import "../utility.module.scss";

.member_info_card {
  max-width: 960px;
  margin: 0 auto;
}

// benefits
.member_info_card_wrap{
  width: 100%;
  max-width: 500px;
  text-align: center;
  box-shadow: 0px 0px 8px 0px rgb(0 0 0 / 20%);
  border-radius: 16px;
  background-color: #ffffff;
  @include pc {
    padding: 34px 50px 38px;
    margin: 34px 0 0 auto;
  }
  @include sp {
    margin: 170px auto 0 auto;
    padding: 20px 26px;
  }
}

.member_info_card_wrap_new{
  width: 100%;
  text-align: center;
  box-shadow: 0px 0px 8px 0px rgb(0 0 0 / 20%);
  border-radius: 16px;
  background-color: #ffffff;
  @include pc {
    margin: 0 auto;
  }
  @include sp {
    margin: 0 auto;
  }
}

// member Name
.member_info {
  display: flex;
  @include pc {
    padding-top: 27px;
  }
}

.member_info_new{
  padding: 16px 24px 6px 24px;
  display: flex;
  justify-content: space-between;
  color: #222;
  font-family: "Noto Sans JP";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  span{
    color: #999;
    font-family: "Noto Sans JP";
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
  }
}

.member_point_new{
  text-align: start;
  color: #222;
  font-family: "Noto Sans JP";
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  span{
    color: var(--main-color) !important;
    font-family: "Noto Sans JP";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
  }
  h3{
    padding: 6px 0;
    text-align: start;
  }
  @include pc{
    display: flex;
    align-items: center;
    gap: 2px;
  }
}

.point{
  color: var(--main-color) !important;
  text-align: right;
  font-family: "Noto Sans JP";
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  span{
    color: #222;
    font-family: "Noto Sans JP";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; 
  }
}

.member_name_txt {
  padding: 10px 0;
  width: 50%;
  font-size: 100%;
  font-weight: bold;
  text-align: left;
}

.member_name_info {
  display: flex;
  align-items: center;
  justify-content: end;
  width: 50%;
  font-size: 12px;
}

.member_name_logout {
  font-size: 12px;
}

.member_name_btn {
  border: 1px solid #dddddd;
  width: 72px;
  height: 28px;
  font-weight: bold;
  display: inline-block;
  border-radius: 4px;
  padding-top: 6px;
  margin: 7px 0 7px 10px;
  text-align: center;
  vertical-align: middle;
}

// member point
.member_point {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 26px 0 30px;
}

.member_point_infor{
  margin: 0 auto;
  padding: 16px 0px;
  border-top: 1px solid #EBEBEB;
  width: calc(100% - 50px);
  display: flex;
  flex-wrap: wrap;
  @include sp{
    justify-content: space-between;
  }
  @include pc{
  }
}


// member point medal
.member_point_medal {
  display: flex;
  width: 50%;
  border-right: 1px solid #dddddd;
  &_new{
    display: flex;
    border-right: none;
    align-items: center;
    gap: 8px;
    @include sp{
      width: 50%;
    }
  }
}

.member_point_medal_img_block {
  margin-top: 17px;
  max-width: 40px;
  max-height: 40px;
}

.member_point_medal_img_block_new {
  width: 36px;
  height: 40px;
}

.member_point_medal_img {
  width: 40px;
  height: auto;
}

.member_point_medal_txt {
  @include sp {
    font-size: 18px;
    font-weight: bold;
    padding: 17px 0;
  }
  font-size: 22px;
  font-weight: bold;
  padding: 15px 0;
}

.member_point_medal_txt_new{
  text-align: start;
  color: #222;
  font-family: "Noto Sans JP";
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  @include sp {
    font-size: 18px;
    font-weight: bold;
  }
  font-weight: bold;
  font-size: 14px;
  h5{
    font-size: 11px;
    margin-bottom: 6px;
  }
}

.progress_bar{
  height: 8px;
  width: 100%;
  background-color: #EBEBEB;
  border-radius: 10px;
  position: relative;
}
.progress_bar_done{
  border-radius: 10px;
  height: 8px;
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--main-color) !important;
}

.button_block{
  width: 100%;
  display: flex;
  background-color: #F4F5F7;
  padding: 24px 24px;
  align-items: center;
  justify-content: space-between;
  svg{
    display: none;
  }
  div{
    position: relative;
    :hover{
      opacity: 0.75;
    }
  }
}

.button{
  width: 45%;
}

.button_active_link{
  background-color: var(--main-color) !important;
  color: #FFF;
  text-align: center;
  font-family: "Noto Sans JP";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  border-radius: 90px;
}

.button_inactive_link{
  color: var(--main-color) !important;
  text-align: center;
  font-family: "Noto Sans JP";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  border: 2px solid var(--main-color) !important;
  line-height: 100%;
  border-radius: 90px;
}

.button_common{
  @include pc{
    height: 48px;
  }
  @include sp{
    height: 32px;
  }
  display: flex;
  align-items: center;
  justify-content: center;
}

.member_point_info {
  width: 50%;
  padding-left: 10px;
  &_new{
    padding-left: 10px;
    @include pc{
      width: 67%;
    }
    @include sp{
      width: 50%;
    }
  }
}

.member_point_info_label {
  text-align: center;
  font-size: 12px;
  font-weight: bold;
}

.member_point_info_txt {
  @include sp {
    text-align: center;
    font-size: 20px;
    letter-spacing: normal;
    padding-right: 30px;
  }
  font-size: 38px;
  font-weight: bold;
  line-height: 1.1;
}

.member_point_info_txt_point {
  @include sp {
    padding-top: 16px;
  }
  @include pc {
    padding-top: 10px;
  }
}

.member_point_info_suffix {
  font-size: 14px;
  font-weight: bold;
}

.member_point_request_btn {
  margin-bottom: 21px;
  width: 100%;
  max-width: 300px;
  @include sp {
    width: 90%;
  }
}

.member_point_request_link {
  text-decoration: none;
  font-weight: bold;
  font-size: 18px;
  @include pc {
    padding: 10px;
  }
  @include sp {
    padding: 15px 10px;
    line-height: 16px;
  }
}

.point_history_link {
  text-decoration: underline;
}

.member_info_card_new{
  padding: 0 !important;
  margin: 0 auto !important;
}

.member_info_card_new_header{
  width: calc(100vw - 50px);
}

.member_info_card_new_top{
  @include pc{
    width: 480px;
  }
}

.member_info_new_pc{
  @include pc{
    padding: 16px 40px !important;
  }
}

.member_point_infor_pc{
  display: flex;
  justify-content: space-between;
  @include pc{
    width: calc(100% - 80px) !important;  
  }  
  @include sp{
    align-items: center;
    justify-content: center;
  }
}

.button_block_pc{
  @include pc{
    padding: 24px 40px !important;
    a{
      display: flex;
      align-items: center;
      justify-content: center;
      width: 192px !important;
      height: 48px;
    }
    svg{
      position: absolute;
      width: 16px;
      height: 16px;
      top: 33%;
      right: 5%;
      color: white;
      display: block;
    }
  }
}

.add_to_home_screen{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
  gap: 2px;
  h4{
    color: #222;
    font-family: "Noto Sans JP";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    text-decoration-line: underline;
  }
  :hover{
    cursor: pointer;
  }
}