@import "../utility.module.scss";

.card {
  width: 100%;
  max-width: 992px;
  border: 1px solid #c7c7c7;
  border-radius: 16px;
  box-shadow: none;
  background: transparent;
  background-color: #FFF;
}

.card_header {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  padding: 16px 8px;
  font-size: 20px;
  background-color: rgba(255, 255, 255, 0.9);
}

.tabs {
  background-color: rgba(255, 255, 255, 0.9);
  height: 45px;
  margin: -10px 0 10px 0;
}

.info_article_list_article_list_inner {
  display: flex;
  flex-wrap: wrap;
}
.none {
  padding: 10px;
}