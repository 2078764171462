@import "../utility.module.scss";

.mail_address_confirm_form {
}

.mail_address_confirm_form_title {
  text-align: center;
  font-size: 23px;
  font-weight: bolder;
  margin-bottom: 28px;
}

.mail_address_confirm_form_txt {
  @include sp {
    margin-bottom: 16px;
    font-size: 12px;
  }
  text-align: center;
  letter-spacing: 0.4px;
  margin-bottom: 24px;
  line-height: 1.8;
}

.mail_address_confirm_form_input {
  margin-bottom: 14px;
  width: 100%;
  padding: 12px 16px;
  background-color: #fff;
  border: 1px solid #dddddd;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  line-height: normal;
  font: inherit;
}

.mail_address_confirm_form_attention_txt {
  color: rgb(255, 0, 0);
  font-size: medium;
  text-align: right;
  line-height: 1;
  text-align: -webkit-center;
}

.mail_address_confirm_form_attention_txt_link {
  text-decoration: underline;
}

.apply_btn {
  @include sp {
    margin-top: 30px;
  }

  width: 276px;
  max-width: 300px;
  margin: 50px auto 21px;
}

.error_message {
  margin: 20px 0;
  color: #f00;
  font-weight: bold;
  font-size: 16px;
}

.email_error_message {
  margin: 0 0 20px 0;
  color: #f00;
  font-weight: bold;
  font-size: 12px;
}
