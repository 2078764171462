@import "../utility.module.scss";

.menu_list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    @include pc {
        gap: 13px;
        max-width: 960px;
        margin: 24px auto 56px auto;
        justify-items: center;
        h4{
            font-size: 14px;
        }
    }
    @include sp{
        margin-top: 16px;
        gap: 13px;
        h4{
            font-size: 10px;
        }
    }
    
    h4 {
        color: #222;
        text-align: center;
        font-family: "Noto Sans JP";
        font-style: normal;
        font-weight: 700;
        line-height: 100%;
        margin-top: 8px;
    }
}

.menu_item {
    border-radius: 12px;
    border: 1px solid #CCC;
    background: #FFF;
    @include pc {
        padding: 14px 96px;
        flex: 1 1 calc(33.333% - 13px);
    }
    @include sp {
        max-width: 88px;
        flex: 1 1 calc(33.333% - 12px);
        padding: 16px;
    }
    div {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.menu_item_link{
    @include sp{
        display: block;
    }
}

.menu_item_link:hover{
    opacity: 0.6;
}

.menu_item_img{
    width: 36px;
    height: 36px;
    object-fit: contain;
}

.link_name{
    @include sp{
        max-height: 30px;
    }
    @include pc{
        max-height: 36px;
    }
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
}

.scratch_container{
    width: 100%;
    @include pc{
        img{
            height: 66px;
            width: 100%;
            object-fit: contain;
        }
    }
    @include sp{
        img{
            height: 71px;
            width: 100%;
            object-fit: contain;
        }
    }
    cursor: pointer;
}

.scratch_container:hover{
    opacity: 0.6;
}
