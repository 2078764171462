@import "../utility.module.scss";
.static_card {
  @include sp {
    padding: 30px 18px;
  }
  @include pc{
    min-height: calc(100vh - 292px);
    margin-bottom: 24px;
  }
  width: 100%;
  max-width: 960px;
  padding: 40px;
  margin: 30px auto 0;
  word-wrap: break-word;
  @extend .card;
  img {
    max-width: 100%;
  }
}

.title {
  position: relative;
  font-size: 22px;
  font-weight: bold;
  text-align: center;
  padding: 0 10px 38px 10px;
  margin-bottom: 40px;
  max-width: 700px;
  margin: 0 auto;
  &:after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 1px;
    bottom: 0;
    left: 0;
    background-color: #dddddd;
  }
}

.body {
  margin-top: 40px;
}
