.selectbox {
  position: relative;
  display: block;
  -webkit-appearance: none;
  appearance: none;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  font-size: 14px;
}
@media only screen and (max-width: 799px) {
  .selectbox:after {
    content: "";
    top: calc(50% - 9px);
    position: absolute;
    right: 10px;
    width: 10px;
    height: 10px;
    border-top: 2px solid var(--main-color);
    border-right: 2px solid var(--main-color);
    transform: translateX(-50%) rotate(135deg);
  }
}
@media only screen and (min-width: 800px) {
  .selectbox:after {
    content: "";
    top: calc(50% - 9px);
    position: absolute;
    right: 10px;
    width: 10px;
    height: 10px;
    border-top: 2px solid var(--main-color);
    border-right: 2px solid var(--main-color);
    transform: translateX(-50%) rotate(135deg);
  }
}

