@import "../utility.module.scss";

.news_time_like_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.news_actionbox {
  text-align: center;
  display: flex;
}

.news_link {
  padding: 16px 0;
  border-bottom: 1px solid #ebebeb;
  a {
    color: #222;
    font-family: "Noto Sans JP";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 160%;
  }
  .time {
    color: #999;
    font-family: "Noto Sans JP";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
  }
}
