@import "../utility.module.scss";

.card {
  width: 100%;
  max-width: 992px;
  border: 1px solid #c7c7c7;
  border-radius: 16px;
  box-shadow: none;
  background: transparent;
}

.card_header {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  padding: 16px 8px;
  font-size: 20px;
  background-color: rgba(255, 255, 255, 0.9);
}

.folder_name {
  font-size: 18px;
  font-weight: 700;
  color: #0e0e0e;
  @media (max-width: 600px) {
    font-size: 14px;
  }
}

.file_name {
  font-weight: 400;
  font-size: 16px;
}

.file_date {
  font-size: 16px;
  font-weight: 400;
  min-width: 70px;
  @media (max-width: 600px) {
    font-size: 12px;
  }
}

.file_date_title {
  font-size: 12px;
  font-weight: 400;
  min-width: 70px;
  @media (max-width: 600px) {
    font-size: 8px;
  }
}

.divider {
  &:last-child {
    display: none;
    background-color: #d3d3d3;
  }
}
