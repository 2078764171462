@import '../utility.module.scss';

.mail_address_confirm_apply_card_title {
  text-align: center;
  font-size: 23px;
  font-weight: bolder;
  margin-bottom: 28px;
}

.mail_address_confirm_apply_card_txt { 
  @include sp { 
    font-size: 14px;
  }

  text-align: center;
  letter-spacing: 0.4px;
  margin-bottom: 24px;
  line-height: 1.8;
}

.mail_address_confirm_apply_card_link {
  text-decoration: underline;
}