@import "../utility.module.scss";

.article_card_thumbnail {
  @include sp {
    height: 49.6vw;
  }
  @include pc {
    height: 186px;
  }
}
.article_card_thumbnail_img {
  width: 100%;
  height: auto;
  max-height:100%;
  border-radius: 16px 16px 0px 0px;
}
.article_card_object_fit {
  object-fit: contain;
}
.article_card_object_fit_none {
  object-fit: none;
}

.img{
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  @include sp {
    height: 100%;
  }
  @include pc{
    height: 270px;
  }
}

.article_card_thumbnail_img_new{
  width: calc(100% - 30px);
  height: 270px;
  object-fit: contain;
  @include sp {
    height: 100%;
  }
  @include pc{
    height: 270px;
  }
}

.likeCard{
  padding: 8px 9px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  h5{
    color: #999;
    font-family: "Noto Sans JP";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    padding-bottom: 1px;
  }
}

.bookmarkCard{
  padding:3px 4px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  h5{
    color: #999;
    font-family: "Noto Sans JP";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
  }
}

.article_card_video_thumbnail_img {
  width: 100%;
  height: 95%;
  position: relative;
  border-radius: 16px 16px 0px 0px;
  background-size: contain;
  background-position: center;
  background-blend-mode: multiply;
  background-repeat: no-repeat;
  background-color: rgba(0, 0, 0, 0.4);
}

.article_card_article_txt {
  min-height: 122px;
  padding: 8px 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @include sp {
    padding: 16px;
  }
}

.article_card_article_title {
  font-family: Noto Sans JP;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  overflow: hidden;
  display: contents;
}

.article_card_article_time {
  font-size: 12px;
  float: left;
  margin-top: 8px;
  display: block;
  color: #888888;
}

.good_count {
  margin-top: 5px;
  float: right;
}
.article_actionbox {
  text-align: center;
  display: flex;
  justify-content: flex-end;
}

.article_card_created_goodcount {
  display: flex;
  justify-content: space-between;
}
.videoWrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.play_button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  z-index: 1;
}

.likeCardContainer{
  position: absolute;
  bottom: 37px;
  right: 12px;
  background: #FFF;
  border-radius: 6px;
  @include sp {
    bottom: 22px;
  }
}
.article_title {
  max-height: 50px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}