@import '../utility.module.scss';

.point_request_apply_card_title {
  text-align: center;
  font-size: 23px;
  font-weight: bolder;
  margin-bottom: 28px;
}

.point_request_apply_card_txt { 
  @include sp { 
    font-size: 12px;
  }
  text-align: center;
  font-size: 16px;
  letter-spacing: 0.4px;
  margin-bottom: 24px;
  line-height: 1.6;
}

.point_request_apply_card_more_btn {
  @include sp { 
    margin-top: 30px;
    max-width: 280px;
    width: 200px;
  }

  margin-top: 78px;
  width: 276px;
  max-width: 300px;
  & * {
    font-size: 16px !important;
  }
  
}

.point_request_apply_card_more_link {
  text-decoration: none;
  font-weight: bold;  
  padding: 15px 10px;
}

.point_request_apply_card_link { 
  @include sp {
    font-size: 12px;
  }
  text-align: center;
  margin-top: 22px;
  font-size: 16px;
  font-weight: bold;
  text-decoration: underline;
}