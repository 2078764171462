@import "../utility.module.scss";

/* 共通のもの */
$card-title-font: Noto Sans JP;
$default-padding: 8px;

/* Title styles with truncation */
.content_card_content_title {
  margin-bottom: 12px;
  flex-shrink: 0;
  color: #000;
  font: {
    family: $card-title-font;
    size: 14px;
    style: normal;
    weight: 700;
  }
  line-height: 1.5em;
  max-height: 3em;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.content_card_content_time {
  width: 86px;
  height: 24px;
  padding: 0 $default-padding;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  color: var(--text-cms-text-gray-w, #757575);
  font: {
    family: $card-title-font;
    size: 12px;
    style: normal;
    weight: 400;
  }
  line-height: normal;
}
.news_time_like_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.time {
  color: #999;
  font-family: "Noto Sans JP";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
}
.news_link {
  padding: 16px 0;
  border-bottom: 1px solid #ebebeb;
  a {
    color: #222;
    font-family: "Noto Sans JP";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 160%;
  }
}
.like_bookmark_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}