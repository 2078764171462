@import '../utility.module.scss';

.button { 
  float: left;
  @include sp {
    font-size: 14px;
    &:nth-child(odd){
      margin-right: 5px;
    }
    &:nth-child(even){
      margin-left: 5px;
    }

    width: 132px;
    height: 33px;
    margin: 10px 0 0;
    padding: 8px;
  }
  width: 205px;
  font-size: 16px;
  margin-bottom: 8px;
  padding: 15px;
  text-align: center;
  height: 50px;
  border-radius: 3px;
  font-weight: bold;
  border: 1px solid #dddddd;
  line-height: 1;
  margin: 5px 7px;

}

.button_fullhouse { 
  @include sp {
    padding: 3px;
  }
  background-color: #dddddd;
  border: 1px solid #777777;
  color: #777777;
  padding: 10px;
}

.full_house_txt { 
  color: #777777;
  font-size: 12px;
}

.event_calendar_time_range_selected{
  background-color: var(--main-color);
  border: 1px solid var(--main-color);
  color: #ffffff;
}
 