@import "../utility.module.scss";

.title {
  position: relative;
  padding-bottom: 36px;
  margin-bottom: 39px;
  text-align: center;
  font-size: 23px;
  font-weight: bolder;
  &::after {
    content: "";
    display: block;
    width: 100%;
    height: 2px;
    background-color: #ddd;
    position: absolute;
    bottom: 0;
    left: 0;
  }

  @include sp {
    padding-bottom: 20px;
    margin-bottom: 20px;
  }
}

.txt {
  text-align: center;
  letter-spacing: 0.4px;
  margin-bottom: 24px;
  line-height: 1.8;
  font-size: 15px;
  @include sp {
    margin-bottom: 16px;
    font-size: 12px;
  }
}

.btn {
  display: block;
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
}

.link {
  text-decoration: underline;
}
