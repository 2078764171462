@import "../utility.module.scss";

.errorCard {
  @include sp {
    padding: 30px 18px;
    max-width: 100%;
  }
  width: 100%;
  max-width: 960px;
  padding: 40px;
  margin: 30px auto 0;
  @extend .card;
}

.title {
  position: relative;
  font-size: 22px;
  font-weight: bold;
  text-align: center;
  padding: 0 10px 38px 10px;
  margin-bottom: 40px;
  max-width: 700px;
  margin: 0 auto;
  &:after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 1px;
    bottom: 0;
    left: 0;
    background-color: #dddddd;
  }
}

.body {
  margin: 40px auto;
  & * {
    font-size: 15px;
    line-height: 120%;
  }
}

.text{
  text-align: center;
}

.link {
  text-decoration: underline;
}
