@import "../utility.module.scss";
.naviMenu {
  @include pc {
    display: flex;
  }

  @include sp {
    & li {
      width: 100%;
      border-top: 1px solid #fff;
      border-bottom: 1px solid #dddddd;
      padding: 18px 0 18px 25px;
      & * {
        font-size: 15px;
      }
      &:hover {
        -moz-box-shadow: inset 0 0 15px 3px #ebebeb;
        -webkit-box-shadow: inset 0 0 15px 3px #ebebeb;
        box-shadow: inset 0 0 15px 3px #ebebeb;
      }
    }
  }
  
}

.naviMenu_new { 
  @include pc {
    display: flex;
    gap: 20px;
  }

  @include sp {
    margin-top: -25px;
    & li {
      border-top: 1px solid #fff;
      border-bottom: 1px solid #dddddd;
      padding: 16px 0;
      margin: 0 25px;
      & * {
        font-size: 15px;
      }
      &:hover { 
        -moz-box-shadow: inset 0 0 15px 3px #ebebeb;
        -webkit-box-shadow: inset 0 0 15px 3px #ebebeb;
        box-shadow: inset 0 0 15px 3px #ebebeb;
      }
    }
  }
}

.naviMenu_link_new{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.naviItem { 
  display: flex;
  
}

.naviMenu_link {
  padding-right: 20px;
}

.naviMenu_link_text {
  padding-top: 3px;
  font-size: 14px;
  font-weight: bold;
}


.naviMenu_icon {
  vertical-align: middle;
  width: 26px;
  height: 26px;
  padding: 3px;
  margin-right: 8px;
  margin-bottom: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  box-shadow: 0px 0px 8px 0px rgb(0 0 0 / 20%);
  background-color: #ffffff;

  img {
    width: 16px;
    object-fit: contain;
  }
}
