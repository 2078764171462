@import "../utility.module.scss";

/* 共通のもの */
$card-title-font: Noto Sans JP;
$default-padding: 8px;

.content_card_wrap {
  width: 100%;
  height: 96px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 16px;
  border-radius: 16px;
  background: var(--bgcmsbg, #f6f6f6);
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.2);
}

.content_card_textwrap {
  width: 100%;
  justify-content: space-between;
}

.content_card_thumbnail {
  width: 96px;
  height: 96px;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content_card_article_thumbnail_img {
  width: 100%;
  height: 100%;
  position: relative;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.content_card_video_thumbnail_img {
  width: 100%;
  height: 100%;
  position: relative;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-blend-mode: multiply;
  background-color: rgba(0, 0, 0, 0.4);
}

.content_card_content_txt {
  min-height: 122px;
  padding: 8px 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* Title styles with truncation */
.content_card_content_title {
  padding: 0 $default-padding;
  margin-bottom: 12px;
  flex-shrink: 0;
  color: #000;
  font: {
    family: $card-title-font;
    size: 14px;
    style: normal;
    weight: 700;
  }
  line-height: 1.5em;
  max-height: 3em;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.content_card_time_goodCount {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.content_card_content_time {
  width: 86px;
  height: 24px;
  padding: 0 $default-padding;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  color: var(--text-cms-text-gray-w, #757575);
  font: {
    family: $card-title-font;
    size: 12px;
    style: normal;
    weight: 400;
  }
  line-height: normal;
}

.good_count {
  display: flex;
  justify-content: end;
}

.wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.playButton {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  z-index: 1;
}
