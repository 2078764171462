@import '../utility.module.scss';

.title {
  text-align: center;
  font-size: 23px;
  font-weight: bolder;
  margin-bottom: 28px;
}

.sub_title { 
  text-align: center;
  font-size: 20px;
  font-weight: bolder;
  margin-bottom: 14px;
}

.text { 
  @include sp { 
    font-size: 14px;
  }
  
  font-size: 15px;
  text-align: center;
  letter-spacing: 0.4px;
  margin-bottom: 24px;
  line-height: 1.8;
}

.btn {
  width: 100%;
  max-width: 300px;
}