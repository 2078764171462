@import "../../utility.module.scss";

.form {
  display: none;
  &:checked + label:before {
    background-color: var(--main-color) !important;
  }
}

.disable {
  @extend .form;
  pointer-events: none;
  background: transparent;
  box-shadow: none;
  border-radius: unset;
}

.label {
  display: inline-block;
  position: relative;
  padding-left: 25px;
  padding-right: 10px;
  font-size: 14px;

  &:before {
    content: "";
    position: absolute;
    display: block;
    box-sizing: border-box;
    width: 18px;
    height: 18px;
    margin-top: -9px;
    left: 0;
    top: 50%;
    border: 1px solid #999;
    background-color: #fff;
    -webkit-border-radius: 50%;
    border-radius: 50%;
  }

  &:after {
    content: "";
    position: absolute;
    display: block;
    box-sizing: border-box;
    width: 10px;
    height: 8px;
    margin-top: -9px;
    top: 15px;
    left: 4px;
    transform: rotate(-45deg);
    border-bottom: 3px solid;
    border-left: 3px solid;
    border-color: #fff;
  }
}
