@import '../utility.module.scss';

.time_range {
  display: block;
  padding-bottom: 15px;
}

.list { 
  display: block;
  margin: 0 auto;
  @extend .clearfix
}