@import '../utility.module.scss';

.info_article_list_article_list {
  @include sp {
    padding: 0;
    margin: 0;
  }
  max-width: 960px;
  margin: 32px auto 0;
}

.info_article_list_article_list_new{
  @include pc{
    min-height: 600px;
  }
  @include sp {
    padding: 0;
    margin: 0;
    min-height: 670px;
  }
  max-width: 960px;
  margin: 32px auto 0;
}


.info_article_list_article_list_title { 
  position: relative;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  padding: 0 10px 16px 10px;
  margin-bottom: 40px;
  margin: 0 auto 32px;
  border-bottom: 1px solid var(--main-color);
  @include sp {
    margin-bottom: 16px;
  }
}
.info_article_list_article_list_inner {
  @extend .clearfix
}

.info_article_list_article {
  display: block;
}

.info_article_list_article_title {
  font-size: 18px;
  font-weight: bold;
  color: #000;
  margin-bottom: 40px;
  line-height: 1.2;
  text-decoration: underline;
}

.info_article_list_article_list_notfound {
  text-align: center;
}