.title {
  position: relative;
  font-size: 22px;
  font-weight: bold;
  text-align: center;
  padding: 0 10px 38px 10px;
  margin-bottom: 25px;
  max-width: 470px;
  margin: 0 auto;
}

.description { 
  text-align: center !important;
  color: #333;
  font-size: 15px;
  line-height: 1.6;  
}


.apply_btn {
  width: 276px;
  max-width: 300px;
  margin: 14px auto 21px;
}