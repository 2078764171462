.app_pagenation { 
  text-align: center !important;
  margin-top: 20px;
}

.app_pagenation_prev_link { 
  margin: 9px;
  border: 0px;
  text-decoration: none;
  font-size: 20px;
  font-weight: bold;  
}

.app_pagenation_next_link { 
  margin: 9px;
  border: 0px;
  text-decoration: none;
  font-size: 20px;
  font-weight: bold;
}


.app_pagenation_prev_number { 
  margin: 9px; 
  display: inline-block;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 5px;

}

.app_pagenation_prev_number_link { 
  display: inline-block;
  padding: 8px 6px;
  min-width: 30px;
  min-height: 30px;
  border-radius: 5px;
  font-size: 14px;
  -moz-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0px 0px 8px 0px rgb(0 0 0 / 20%);
  box-shadow: 0px 0px 8px 0px rgb(0 0 0 / 20%);
  color: #000;
  text-decoration: none;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  line-height: 1;

}

.disable { 
  pointer-events: none;
  color: #e2e2e2 !important;
}

.btn_current{
  background-color: var(--main-color);
  a{
    color: #FFF;
  }
}