@import "../../../utility.module.scss";

.container {
  display: inline-block;
  width: 100%;
}

.icon_conatainer {
  position: relative;
}

.icon {
  position: absolute;
  top: -26px;
  right: 10px;
  font-size: 15px;
}
