@import '../utility.module.scss';

.title {
  position: relative;
  font-size: 22px;
  font-weight: bold;
  text-align: center;
  padding: 0 10px 38px 10px;
  margin-bottom: 25px;
  max-width: 470px;
  margin: 0 auto;
}

.description {
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}

.text {
  @include sp { 
    text-align: left;
    font-size: 12px;
  }
  margin-top: 12px;
  line-height: 175%;
  font-size: 14px;
  letter-spacing: -.5px;
  text-align: center;
}

.btn { 
  max-width: 300px;
  margin-top: 20px !important;
  text-align: center !important;

  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  -webkit-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;

  //.mod-btn-02 
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid #dddddd;
  background-color: #fff;
  color: #000;
  display: block;
  font-size: 18px;
  font-weight: 500;
  text-decoration: none;
  position: relative;
  margin: 0 auto;
  padding: 0;
  -moz-border-radius: 26px;
  -webkit-border-radius: 26px;
  border-radius: 26px;
  padding: 15px 10px;
}