@import '../utility.module.scss';
.cell { 
  @include sp {
    max-width: 35px;
    height: 45px;
    padding: 15px 0 !important;
    margin: 4px;
    font-size: 14px;
  }
  display: block;
  font-size: 16px;
  width: 14.28%;
  text-align: center;
  margin: 4px;
  padding: 8px;
  border-radius: 3px;
  line-height: 1;
  height: 50px;
  padding: 15px;
  font-weight: 500;
}

.cell_disable { 
  background-color: #dddddd;
  border: 1px solid #dddddd;
  color: #ffffff;
}

.cell_fullhouse { 
  background-color: #dddddd;
  border: 1px solid #777777;
  color: #777777;
  padding: 10px 0 !important;
}

.full_house_txt { 
  @include sp {
    font-size: 10px;
  }

  color: #777777;
  font-size: 16px;
}

.event_calendar_target_date_clicked{
  background-color: var(--main-color);
  border: 1px solid var(--main-color);
  color: #ffffff;
}

.event_canlendar_target_date{
  background-color: #ffffff;
  border: 1px solid var(--main-color);
  color: var(--main-color);
}