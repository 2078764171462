@import "../utility.module.scss";
.footer {
    margin-top: 120px;
    border-top: 1px solid var(--main-color);
}

.footer_with_bg {
    background-color: #fff;
    margin-top: 120px;
}

.footer_new {
    margin-top: 0;
}

.footer_block {
    @include sp {
        max-width: 100%;
    }
    @include pc {
        width: 100%;
        margin: 0 auto;
    }
}

.footer_container {
    border-top: 1px solid var(--main-color);
    @include sp {
        padding: 30px 0 0;
    }
    padding: 48px 0 0;
}

.footer_container_new {
    @include sp {
        padding: 30px 0 70px 0;
    }
    padding: 32px 0 24px 0;
    background-color: #222;
}

.footer_container_new_no_navi {
    @include sp {
        padding: 30px 0 0px 0 !important;
    }
}

.footer_nav {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    max-width: 530px;
    margin: 0 auto;
}

.footer_nav_new {
    @include pc {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        row-gap: 16px;
        width: 100%;
        margin: 0 auto;
         :last-child {
            a {
                div {
                    display: none;
                }
            }
        }
    }
    @include sp {
        padding: 0 24px;
        display: grid;
        grid-template-columns: auto auto auto;
        row-gap: 16px;
         :nth-child(3) {
            a {
                div {
                    display: none;
                }
            }
        }
    }
}

.footer_nav_item {
    margin: 0 0 20px 2%;
    text-align: center;
    width: 23%;
}

.footer_nav_item_new {
    text-align: center;
    margin-right: 16px;
}

.footer_nav_item_link {
    @include sp {
        font-size: 12px;
    }
    color: #000;
    font-size: 14px;
    font-weight: bold;
    text-decoration: none;
}

.footer_nav_item_link_new {
    color: #FFF;
    font-family: "Noto Sans JP";
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    div {
        height: 8px;
        width: 1px;
        background-color: #ccc;
    }
}

.footer_copy_right {
    padding: 32px 0 12px;
    font-size: 12px;
    color: #888888;
    text-align: center;
}

.footer_copy_right_new {
    font-size: 12px;
    color: #888888;
    text-align: center;
    color: #ECECEC;
    font-family: "Noto Sans JP";
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    @include pc {
        padding: 32px 0 0px;
    }
    @include sp {
        padding: 24px 0 16px;
    }
}

// common
.clearfix {
    *zoom: 1;
    &:after {
        content: "";
        display: table;
        clear: both;
    }
}

.bottom_bar_container {
    @include pc {
        max-width: 460px;
        margin: 24px auto 0 auto;
        height: 72px;
        border-radius: 60px;
        box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.20);
        overflow: hidden;
        border-top: #CCCCCC 1px solid;
    }
    @include sp {
        position: fixed;
        bottom: 0;
        left: 0;
        z-index: 999;
        border-top: #CCCCCC 1px solid;
    }
}

.bottom_bar {
    height: 70px;
    display: flex;
    align-items: center;
    text-align: center;
    background: #FFF;
    @include pc {
        height: 72px;
        padding: 0 10px;
    }
    @include sp {
        justify-content: space-between;
        width: 100vw;
        padding: 4px 4px 24px 4px;
    }
}

.nav_item {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    position: relative;
    @include pc {
        width: 88px;
        height: 40px;
    }
    @include sp {
        min-width: 75px;
        height: 38px;
    }
    h5 {
        color: #222;
        text-align: center;
        font-family: "Noto Sans JP";
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 100%;
    }
    &_active {
        h5 {
            color: var(--main-color) !important;
            font-weight: bold;
        }
        svg:first-child {
            display: none !important;
        }
        pointer-events: none;
    }
    &_inactive {
        svg:nth-child(2) {
            display: none !important;
        }
    }
}

.nav_item_inner_active {
  border-bottom: 4px solid var(--main-color);
  border-radius: 4px;
  padding: 2px;
}

.nav_item:hover {
    opacity: 0.6;
}

.sp {
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 999;
}

.chat_item {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
        display: block !important;
    }
}

.chat_notification {
    width: 12px;
    height: 12px;
    background: red;
    border-radius: 50%;
    position: absolute;
    @include pc {
        top: -2px;
        right: 30px;
    }
    @include sp {
        top: -2px;
        right: 25px;
    }
}

.icon,
.iconActive {
  min-width: 18px;
  min-height: 18px;
  max-width: 24px;
  max-height: 24px;
}

.icon {
  opacity: 0.5;
}

.text {
  opacity: 0.5;
}

.textActive {
  opacity: 1;
}
