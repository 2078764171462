@import "../utility.module.scss";

.step_container {
  position: relative;
  width: 100%;
}
.step_content {
  position: absolute;
  top: 0;
  left: 0;
}

.title {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  margin: 12% 0 10px;
  padding-top: 15px;
}

.discription {
  @include sp {
    font-size: 12px;
  }
  font-size: 14px;
  text-align: center;
}

.inner {
  margin: 5px;
  padding: 5px;
  background-color: #f6f6f6;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
}

.button_list {
  @include sp {
    display: block;
    padding: 0 5px 20px;
  }
  display: flex;
  margin-top: 30px;
  padding: 0 250px;
}

.button {
  @include sp {
    width: 100%;
    height: 50px;
    margin-top: 10px;
  }
  width: 150px;
  & * {
    font-size: 14px;
    font-weight: bold;
  }
}

.table {
  margin-top: 14px;
  width: 100%;
  & th {
    @include sp {
      display: block;
      padding: 0;
      width: 100%;
      text-align: left;
      font-size: 14px;
    }
    width: 41%;
    text-align: right;
    padding: 0 30px 15px 0;
    font-size: 14px;
    vertical-align: middle;
    line-height: 1.2;
  }
  & td {
    @include sp {
      padding-right: 0;
      width: 100%;
      display: block;
      & * {
        font-size: 14px;
      }
    }
    width: 59%;
    padding: 0 0 15px 0;
    vertical-align: middle;
  }
}

.third_step_content path:nth-child(4) {
  fill: var(--main-color);
}
