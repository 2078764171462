@import "../utility.module.scss";

@mixin commonCard {
  display: block;
  position: relative;
  max-width: 176px;
  max-height: 96px;
  width: 18.64vw;
  height: 9.9vw;
  border-radius: 8px;
  @include sp {
    width: 112px;
    height: 56px;
  }
}

@mixin commonName {
  text-align: center;
  position: absolute;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  height: 25px;
  top: 50%;
  padding: 0 4px;
  transform: translateY(-50%);
}

.category_card {
  @include commonCard;
  background: lightgray 50% / cover no-repeat, #fff;
}
.category_card:hover,
.noimg_category_card:hover {
  opacity: 0.65;
}

.category_card_link {
  display: block;
  width: 100%;
  height: 100%;
}

.category_card_thumbnail_img {
  border-radius: 8px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.category_card_name {
  @include commonName;
  background: rgba(255, 255, 255, 0.8);
  font-family: Noto Sans JP;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  @include sp {
    font-size: 12px;
    height: 17px;
  }
}

.noimg_category_card {
  @include commonCard;
  border: 2px solid var(--main-color);
  background: var(--button-cms-ter-btn, #fff);
}

.noimg_category_card_name {
  @include commonName;
  font-size: 16px;
  font-weight: 700;
  color: var(--main-color);
  @include sp {
    font-size: 12px;
    height: 17px;
  }
}

.card_block_new {
  height: 40px;
  padding: 0 16px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  justify-content: center;
  max-width: 300px h4 {
    color: #222;
    text-align: center;
    font-family: "Noto Sans JP";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
  }
}

.card_block_new:hover,
.card_block_active:hover {
  cursor: pointer;
}

.card_block_active {
  padding: 13px 21px 21px 13px;
  border-radius: 12px 12px 0px 0px;
  background: var(--main-color);
  justify-content: center;
  max-width: 300px;
  h4 {
    color: #fff;
    text-align: center;
    font-family: "Noto Sans JP";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
  }
}

.card_block_new,
.card_block_active {
  min-width: 100px;
}

@include sp {
  .categorySlide {
    max-width: 200px;
  }

  .card_block_new,
  .card_block_active {
    min-width: 80px;
    height: 36px;
  }
}
