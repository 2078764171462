@import "../utility.module.scss";

.title {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
  padding-top: 15px;
}

.description {
  @include sp {
    font-size: 12px;
  }
  font-size: 14px;
  text-align: center;
}

.inner {
  margin: 15px 5px;
  padding: 5px;
  background-color: #f6f6f6;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
}

.sub_inner {
  padding: 10px;
  & * {
    font-size: 12px;
  }

  @include pc {
    padding: 15px;
    & * {
      font-size: 14px;
    }
    text-align: center;
  }
}

.header {
  font-weight: bold;
  @include pc {
    margin-top: 20px;
    text-align: center;
  }
}

.link {
  text-decoration: underline;
}

.btn {
  position: relative;
  width: 100%;
  margin: 28px 0;
  @include pc {
    margin: 28px auto;
    max-width: 200px;
  }
}

.btn_link {
  font-size: 14px !important;
  font-weight: bold;
  padding: 10px 15px;
}
