@import "../../../utility.module.scss";

.form {
  @include sp {
    width: 100%;
  }
  margin-bottom: 0;
  padding: 0 16px;
  margin-top: 8px;
  width: 100%;
  height: 35px;
  font-size: 14px;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: #fff;
  border: 1px solid #D3D3D3;
  border-radius: 5px;
}

.disable {
  @extend .form;
  pointer-events: none;
  background: transparent;
  box-shadow: none;
  border-radius: unset;
}

.readOnly {
  @extend .form;
  border: none;
  padding: 0;
  font-weight: 500;
  background-color: transparent;
}

.error {
  @include sp {
    width: 100%;
  }
  margin-bottom: 0;
  padding: 13px 16px;
  margin-top: 8px;
  width: 100%;
  height: 35px;
  font-size: 14px;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: #fff;
  border: 1px solid #EF2628;
  border-radius: 5px;
}

.error_message {
  font-size: 12px;
  color: #EF2628;
}
