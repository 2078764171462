@import "../utility.module.scss";

.header {
  transition: all 0.2s;
  width: 100%;
  position: fixed;
  z-index: 120;
  -moz-box-shadow: 0 3px 2px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 3px 2px rgb(0 0 0 / 20%);
  box-shadow: 0 3px 2px rgb(0 0 0 / 20%);
  -webkit-transform: translateY(0);
  transform: translateY(0);
  @include sp {
  }
  &__hide {
    width: 100%;
    transition: all 0.2s;
    position: fixed;
    z-index: 120;
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
}

.header_new{
  transition: all 0.2s;
  width: 100%;
  position: fixed;
  z-index: 120;
  -webkit-transform: translateY(0);
  transform: translateY(0);
  @include sp {
    position: fixed;
    top: 0;
    left: 0;
  }
  &__hide {
    width: 100%;
    transition: all 0.2s;
    position: fixed;
    z-index: 120;
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
}

.header_nav {
  display: flex;
  max-width: 960px;
  @include sp {
    align-items: center;
    justify-content: space-between;
  }
  @include pc {
    justify-content: space-between;
    padding-bottom:12px ;
  }
}

.nav_new{
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 13px;
  border-top: 1px solid #DDDDDD;
}

.header_logo {
  @include sp {
    /*
    width: -moz-calc(100% - 24px);
    width: -o-calc(100% - 24px);
    width: -webkit-calc(100% - 24px);
    width: calc(100% - 24px);
    */
    width: 18.89vw;
    max-width: 120px;
  }
  width: 170px;
  height: auto;
  // margin-right: 80px;
}

.header_logo_new{
  @include sp {
    img{
      width: 110px;
      height: 32px;
      object-fit: contain;
    }
    height: 40px;
    img{
      height: 32px;
      object-fit: contain;
    }
  }
  display: flex;
  align-items: center;
  justify-content: center;
  @include pc{
    height: 64px;
    img{
      height: 75px;
      width: 150px;
      object-fit: contain;
    }
  }
}

.header_link{
  font-family: "Noto Sans JP";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  margin-left: 16px;
  text-decoration: underline;
}

.header_logo_img {
  z-index: 120;
  max-width: 100%;
  height: auto;
}

.header_nav_wrap {
  display: flex;
  align-items: center;
}


.header_nav_search {
  @include sp {
    margin-left: 14.4vw;
  }
}
