@import "../utility.module.scss";
.list_file{
    @include pc{
        width: 400px;
    }
    @include sp{
        width: 300px;
    }
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 10px;
}

.image_container{
    position: relative;
}

.close_icon{
    width: 26px;
    height: 26px;
    position: absolute;
    top: 1px;
    right: 1px;
    padding: 5px;
    background: rgba($color: #000000, $alpha: 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.close_icon:hover{
    opacity: 0.8;
}

.dialog_header_container{
    @include pc{
        font-size: 14px;
    }
    @include sp{
        font-size: 12px;
    }
    display: flex;
    align-items: center;
    justify-content: space-between;
}


.header_close_button{
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:hover{
        opacity: 0.8;
    }
}

.dialog_header{
    @include pc {
        width: 450px;
    }
    @include sp{
        font-size: 11px !important;
    }
    border-bottom: 1px solid #bcb7b7;
}

.image_preview{
    height: 120px;
    width: 120px;
    object-fit: contain;
}

.dialog_footer_container{
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 !important;
    overflow: hidden;
}

.close_button{
    padding: 8px 0 8px 0;
    border-radius: 0;
    width: 50%;
    background-color: var(--sub-color);
}

.close_button:hover{
    opacity: 0.8;
}

.send_button{
    color: #FFF;
    padding: 8px 0 8px 0;
    border-radius: 0;
    width: 50%;
    background-color: var(--main-color);
    margin: 0 !important;
}

.send_button:hover{
    opacity: 0.8;
}

.file_container{
    height: 120px;
    width: 120px;
    background-color: #666464;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.file_container div {
    margin-top: 30px;
    color: #FFF;
    width: 100%;
    max-height: 40px;
    font-size: 12px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
}

.file_icon{
    height: 36px;
    width: 36px;
}

.sendbird-bottomsheet{
    z-index: 999;
}
