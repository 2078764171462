// アニメーションの時間
$animateTime: 800ms;
// パーティクルの数
$particleNum: 14;

@mixin centering {
  transform-origin: 250px 250px;
}

@mixin animationFill {
  animation-fill-mode: forwards;
}

.isClicked {
  .heart {
    fill: #E2264D;
    transform: scale(1);
    @include centering;
  }

  .explosion,
  .particleLayer {
    display: none;
  }
}

.likeButton {
  cursor: pointer;

  .border {
    fill: rgb(255, 255, 255);
  }
  .explosion {
    transform-origin: 250px 250px;
    transform: scale(0.02);
    stroke: rgba(221, 70, 136, 1);
    fill: none;
    opacity: 0;
    stroke-width: 1;
    @include centering;
  }

  .particleLayer {
    opacity: 0;

    circle {
      opacity: 0;
      @include centering;
    }
  }

  .heart {
    fill: #FFF;
    stroke:rgb(135, 134, 124);
    stroke-width: 20;
    transform: scale(1);
    @include centering;
  }

  &.clicked {
    .explosion {
      animation: explosionAnime $animateTime;
      @include animationFill;
    }

    .heart {
      stroke: none;
      animation: heartAnime $animateTime;
      @include animationFill;
    }

    .particleLayer {
      animation: particleLayerAnime $animateTime;
      @include animationFill;

      @for $index from 1 through $particleNum {
        circle:nth-child(#{$index}) {
          animation: particleAnimate#{$index} $animateTime;
          @include animationFill;
        }
      }
    }
  }
}

// 爆発のアニメーション
@keyframes explosionAnime {
  0% {
    opacity: 0;
    transform: scale(0.01);
  }
  1% {
    opacity: 1;
    transform: scale(0.01);
  }

  5% {
    stroke-width: 200;
  }

  20% {
    stroke-width: 300;
  }

  50% {
    stroke: rgba(204, 142, 245, 1);
    transform: scale(1.1);
    stroke-width: 1;
  }
  50.1% {
    stroke-width: 0;
  }

  100% {
    stroke: rgba(204, 142, 245, 1);
    transform: scale(1.1);
    stroke-width: 0;
  }
}

// パーティクル全体のアニメーション
@keyframes particleLayerAnime {
  0% {
    transform: translate(0, 0);
    opacity: 0;
  }
  30% {
    opacity: 0;
  }
  31% {
    opacity: 1;
  }

  60% {
    transform: translate(0, 0);
  }

  70% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: translate(0, -20px);
  }
}

// パーティクルの個別アニメーション

// 各点の移動位置
// 1点目のx座標, 1点目のy座標, 2点目のx座標, 2点目のy座標,...と並んでいる
$points: -16, -59,
41, 43,
50, -48,
-39, 36,
-39, 32,
48, 6,
-69, -36,
-12, -52,
-43, -21,
-10, 47,
66, -9,
40, -45,
29, 24,
-10, 50;

$pointer: 1;

@for $index from 1 through $particleNum {
  @keyframes particleAnimate#{$index} {
    0% {
      transform: translate(0, 0);
    }
    30% {
      opacity: 1;
      transform: translate(0, 0);
    }
    80% {
      transform: translate(#{nth($points, $pointer)}px, #{nth($points, $pointer + 1)}px);
    }
    90% {
      transform: translate(#{nth($points, $pointer)}px, #{nth($points, $pointer + 1)}px);
    }
    100% {
      opacity: 1;
      transform: translate(#{nth($points, $pointer)}px, #{nth($points, $pointer + 1)}px);
    }
  }
  $pointer: $pointer + 2;
}

// ハートのアニメーション
@keyframes heartAnime {
  0% {
    transform: scale(0);
    fill: #E2264D;
  }
  39% {
    transform: scale(0);
  }
  60% {
    transform: scale(1.2, 1.2);
  }
  70% {
    transform: scale(1, 1) translate(0%, -10%);
  }
  75% {
    transform: scale(1.1, 0.9) translate(0%, 5%);
  }
  80% {
    transform: scale(0.95, 1.05) translate(0%, -3%);
  }
  100% {
    transform: scale(1.0, 1.0) translate(0%, 0%);
    fill: #E2264D;
  }
}