@import "../../utility.module.scss";

.pcLayout {
  display: inline-flex;
}

.pcLayout input {
  width: 231px;
}

.pcLayout_first input {
  margin-right: 5px;
}
