@import "../utility.module.scss";


.custom_accordion {
  background-color: rgba(255, 255, 255, 0.9);
  margin: 0 !important;  
}

.custom_accordion_summary {
  margin-bottom: -1px;
  min-height: 58px;

  &.Mui-expanded {
    min-height: 58px;
  }

  @media (max-width: 600px) {
    min-height: 32px;

    &.Mui-expanded {
      min-height: 32px;
    }
  }
  .MuiAccordionSummary-content {
    &.Mui-expanded {
      margin: 12px 0;
    }
  }
}

.custom_accordion_details {
  cursor: pointer;
  padding: 16px 24px;

  @media (max-width: 600px) {
    padding: 8px 24px;
  }
}
