@import "../../utility.module.scss";

.form {
  margin-bottom: 0;
  font-size: 14px;
  width: auto;
  height: 35px;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid #dddddd;
  border-radius: 5px;
}

.disable {
  @extend .form;
  pointer-events: none;
  background: transparent;
  box-shadow: none;
  border-radius: unset;
  font-size: 14px;
  border: 1px solid #dddddd;
}

.unit {
  margin: 0 2px;
  font-size: 14px;
  vertical-align: middle;
}