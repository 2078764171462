.header { 
  width: 100%;
  font-size: 14px;
  padding: 5px 10px;
  font-weight: bold;
  text-align: center;
  border-radius: 5px;
  margin-bottom: 15px;
  background-color: var(--main-color);
}

.arrow_disable * { 
  stroke: #ffffff;
  fill: #ffffff;
}

.arrow_left { 
  float: left;
}

.arrow_right { 
  float: right;
}

.clickable {
  stroke: var(--main-color);
  fill: var(--main-color);
}
