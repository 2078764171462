@import "../utility.module.scss";
.point_request_card {
    max-width: 550px;
    width: 100%;
    padding: 38px 40px 38px;
    background-color: #ffffff;
    border-radius: 16px;
    margin: 30px auto 0;
    -moz-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0px 0px 8px 0px rgb(0 0 0 / 20%);
    box-shadow: 0px 0px 8px 0px rgb(0 0 0 / 20%);
}

.point_request_card_title {
    font-size: 22px;
    font-weight: bold;
    text-align: center;
    padding: 0 0 38px;
}

.point_request_card_method {
    padding: 19px 19px;
    border-radius: 10px;
    background-color: var(--sub-color);
}

.point_request_card_method_title {
    @include sp {
        display: block;
        text-align: center;
    }
    display: inline-block;
    font-size: 16px;
    font-weight: bold;
    vertical-align: middle;
}

.description {
    color: #333;
    font-size: 15px;
    line-height: 1.2;
}

.point_request_card_method_select {
    overflow: hidden;
    @include sp {
        display: block;
        margin: 10px auto 0;
        width: 100%;
        max-width: 250px;
        -moz-border-radius: 4px;
        -webkit-border-radius: 4px;
        border-radius: 4px;
    }
    display: inline-block;
    margin-left: 15px;
    width: 250px;
    vertical-align: middle;
}

.point_request_card_method_form_selectbox {
    padding: 13px 16px;
    width: 100%;
}

.point_request_card_request_form {
    margin-top: 39px;
    display: block;
}

.point_request_card_request_form_title {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    letter-spacing: -0.5px;
}

.point_request_card_request_form_required_text {
    color: #ef2628;
    margin: 16px 0;
    font-size: 12px;
}

.form_item {
    margin: 10px 0 25px;
}

.form_item_label {
    margin-bottom: 14px;
    word-break: break-word;
}

.form_item_text {
    width: 100%;
    padding: 12px 16px;
    border: 1px solid #dddddd;
    font-size: 14px;
}

.form_item_selectbox {
    border: 1px solid #dddddd;
    width: 100%;
    padding: 13px 16px;
}

.point_request_card_request_form_request_btn {
    width: 276px;
    max-width: 300px;
    margin: 0 auto 21px;
}

.point_request_card_method_letter_content {
    white-space: pre-wrap;
    word-break: break-word;
    text-align: center;
    font-weight: 700;
    & a {
        text-decoration: underline;
        color: #1a0dab;
    }
}