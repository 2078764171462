@import "../../utility.module.scss";

.form {
  @include sp {
    width: 100%;
  }

  margin-bottom: 0;
  padding: 13px 16px;
  width: 300px;
  height: 35px;
  font-size: 14px;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: #fff;
  border: 1px solid #dddddd;
  margin-left: -15px;
  padding-left: 40px !important;
}

.disable {
  @extend .form;
  pointer-events: none;
  background: transparent;
  box-shadow: none;
  border-radius: unset;
}

.icon {
  position: relative;
  font-weight: 900 !important;
  top: 5px;
  left: 15px;
  font-size: 15px !important;
}
