@import "../../utility.module.scss";

.formArea {
  display: inline-flex;
  align-items: flex-end;
  width: 100%;
}

.formArea input {
  width: calc(100% - 104px);
  margin-right: 8px;
}

.formArea button {
  border-radius: 60px;
}

.button {
  width: 96px;
  height: 35px;
  border: 2px solid var(--main-color);
  background-color: var(--main-color);
}

.button:hover{
  opacity: 0.6;
}

.button:focus {
  border: 2px solid #0000FF;
}

.button_label {
  font-size: 16px;
  vertical-align: middle;
  color: #ffffff;
  font-weight: 700;
}
