@import "../utility.module.scss";

.button_next,
.button_prev,
.button_prev_videos,
.button_next_videos,
.button_prev_videos_new,
.button_next_videos_new{
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: var(--main-color);
  position: absolute;
  top: 50%;
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  &:after {
    content: "";
    display: block;
    width: 14px;
    height: 14px;
    border-bottom: 3px solid #fff;
    border-right: 3px solid #fff;
    position: absolute;
  }
}

.button_next_videos_new, .button_prev_videos_new{
  background-color: var(--main-color);
}

.button_next,
.button_next_videos {
  right: -52px;
  &::after {
    right: 13px;
    transform: rotate(-45deg);
  }
  @include sp {
    display: none;
  }
}

.button_next_videos_new{
  top: 82px;
  right: -52px;
  &::after {
    right: 13px;
    transform: rotate(-45deg);
  }
  @include sp {
    display: none;
  }
}

.button_prev,
.button_prev_videos {
  left: -52px;
  &::after {
    left: 13px;
    transform: rotate(135deg);
  }
  @include sp {
    display: none;
  }
}

.button_prev_videos_new{
  top: 82px;
  left: -52px;
  &::after {
    left: 13px;
    transform: rotate(135deg);
  }
  @include sp {
    display: none;
  }
}

.banner_button_next,
.banner_button_prev {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: var(--main-color) !important;
  position: absolute;
  top: 50%;
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  &:after {
    content: "";
    display: block;
    width: 14px;
    height: 14px;
    border-bottom: 3px solid #fff;
    border-right: 3px solid #fff;
    position: absolute;
  }
}

.banner_button_next {
  right: -42px;
  top: 10px;
  &::after {
    right: 13px;
    transform: rotate(-45deg);
  }
  @include sp {
    display: none;
  }
}

.banner_button_prev {
  left: -42px;
  top: 10px;
  &::after {
    left: 13px;
    transform: rotate(135deg);
  }
  @include sp {
    display: none;
  }
}

.pagination,
.pagination_videos {
  position: absolute;
  text-align: center;
  transition: 300ms opacity;
  transform: translate3d(0, 0, 0);
  z-index: 10;
  @include sp{
    bottom: -20px !important;
  }
  @include pc{
    bottom: -5px !important;
  }
}

.pagination_videos {
  bottom: -32px !important;
}

.pagination_bullet,
.pagination_bullet_videos {
  width: 11px;
  height: 11px;
  display: inline-block;
  border-radius: 50%;
  background: #999999;
  cursor: pointer;
  margin: 0 4px;
}

.pagination_bullet_active,
.pagination_bullet_active_videos {
  opacity: 1;
  background: var(--main-color);
}

.pagination_bullet_active_new,
.pagination_bullet_active_videos_new {
  opacity: 1;
  background: var(--main-color) !important;
}

.pagination_horizontal {
}

.banner_pagination {
  position: relative;
  left: 70% !important;
  transform: translateX(-70%);
  bottom: -5px !important;
  text-align: center;
  transition: 300ms opacity;
}

.banner_pagination_bullet {
  width: 9px;
  height: 9px;
  display: inline-block;
  border-radius: 50%;
  background: #999999;
  cursor: pointer;
  margin: 0 4px;
}

.banner_pagination_bullet_active {
  opacity: 1;
  background: var(--main-color);
}

.category_swiper {
  height: auto;
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.category_swiper .swiper-slide {
  width: 112px;
  height: auto;
}

.swiper_container {
  width: 100%;
  overflow: hidden;
}

.slide_new{
  width: auto !important;
}