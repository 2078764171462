@import "../utility.module.scss";
.member_edit_form {
  @include sp {
    padding: 30px 26px !important;
  }
  padding: 40px 50px !important;
}

.member_edit_form_title {
  position: relative;
  font-size: 22px;
  font-weight: bold;
  text-align: center;
  padding: 0 10px 38px 10px;
  margin-bottom: 25px;
  max-width: 470px;
  margin: 0 auto;
  &:after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 1px;
    bottom: 0;
    left: 0;
    background-color: #dddddd;
  }
}

.member_edit_form_form {
  width: 100%;
  margin: 36px auto 0;
}

.member_edit_form_table {
  margin-top: 14px;
  width: 100%;
  & tr {
    @include sp {
      display: block;
    }
  }
  & th {
    @include sp {
      display: block;
      padding: 0 0 8px;
      width: 100%;
      text-align: left;
      font-size: 14px;
    }

    width: 150px;
    text-align: right;
    padding: 0 20px 20px 0;
    font-size: 14px;
    vertical-align: middle;
    line-height: 1.2;
  }
  & td {
    @include sp {
      padding-right: 0;
      display: block;
      padding: 0 0 20px 0;
      width: 100%;
    }

    width: 301px;
    padding: 0 0 20px 0;
    font-size: 0;
    vertical-align: middle;
  }
}

.member_edit_form_text {
  font-weight: bold;
  font-size: 12px !important;
}

.member_edit_form_change_password_btn {
  display: block;
  font-size: 14px;
  width: 170px;
  margin: 0 10px 18px auto;
  padding: 6px 10px 6px 0px;
  position: relative;
  cursor: pointer;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid #dddddd;
  -moz-border-radius: 26px;
  -webkit-border-radius: 26px;
  border-radius: 26px;
  font-weight: 500;
  text-decoration: none;
  text-align: center;
  &:after {
    content: "▼";
    font-size: 10px;
    position: absolute;
    top: -moz-calc(50% - 5px);
    top: -o-calc(50% - 5px);
    top: -webkit-calc(50% - 5px);
    top: calc(50% - 5px);
    right: 5px;
  }
  &:hover {
    background-color: #fff;
  }
}

.member_edit_form_change_password_btn_open {
  @extend .member_edit_form_change_password_btn;
  &:after {
    content: "▲";
  }
  color: var(--main-color);
  border-color: var(--main-color);
}

.member_edit_form_change_password_btn:hover {
  color: var(--main-color);
  border-color: var(--main-color);
}

.apply_btn {
  width: 276px;
  max-width: 300px;
  margin-top: 30px !important;
  margin: 0 auto;
}

.push_notification_btn {
  border: 1px solid #dddddd;
  border-radius: 4px;
  padding: 4px 0px;
  font-size: 12px;
  cursor: pointer;
  text-decoration: underline;
  display: flex;
  align-items: center;

  &:hover {
    opacity: 0.7;
  }
}
