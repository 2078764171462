@import "../utility.module.scss";

.contact_form_title {
  position: relative;
  font-size: 22px;
  font-weight: bold;
  text-align: center;
  padding: 0 10px 38px 10px;
  margin-bottom: 25px;
  max-width: 470px;
  margin: 0 auto;
}

.contact_form_content_attention {
  @include sp {
    font-size: 12px;
  }
  color: #ef2628;
  margin-left: 15px;
}

.contact_form_input {
  border: 2px solid var(--sub-color);
  width: 100%;
  padding: 12px 16px;
  background-color: #fff;
  box-sizing: border-box;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  font: inherit;
}

.apply_btn {
  width: 276px;
  max-width: 300px;
  margin: 14px auto 21px;
  background-color: var(--main-color);
}

.apply_btn:hover{
  opacity: 0.6;
}
