$media-pc: 800px;
$media-sp: 799px;

@mixin pc {
  @media only screen and (min-width: $media-pc) {
    @content;
  }
}
@mixin sp {
  @media only screen and (max-width: $media-sp) {
    @content;
  }
}

.font_bold {
  font-weight: bold;
}

.main {
  @include sp {
    padding-top: 82px;
  }
  padding-top: 128px;
}

.main_new{
  @include sp {
    padding-top: 52px;
    min-height: calc(100vh - 190px);
    margin: 20px 0;
  }
  @include pc{
    min-height: calc(100vh - 110px);
  }
  padding-top: 128px;
}

// NOTE(naotoshin72): イベント画面以外の共通container、基本Presenterで定義する
.container_large {
  @include sp {
    max-width: 100%;
    padding: 0 4.2vw;
  }
  @include pc {
    width: 100%;
    margin: 0 auto;
  }
}

.container_form {
  width: 100%;
  max-width: 550px;
  margin: 0 auto;
  margin-top: 40px;
  padding: 38px 40px 38px;
  background-color: #fff;
  @extend .card;
}

.container_event {
  @include sp {
    max-width: 100%;
    margin: 40px 20px 0;
  }
  @include pc {
    max-width: 960px;
    width: 100%;
  }
  background-color: #fff;
  margin: 0 auto;
  @extend .card;
}

.container_event_inner {
  @include sp {
    width: 100%;
    padding: 20px 20px;
  }
  padding: 40px;
}

.dn_pc {
  @include pc {
    display: none !important;
  }
}

.dn_sp {
  @include sp {
    display: none !important;
  }
}

.dn {
  display: none !important;
}

.img_fit {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.card {
  background-color: rgba(255, 255, 255, 0.88);
  -moz-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0px 0px 8px 0px rgb(0 0 0 / 20%);
  box-shadow: 0px 0px 8px 0px rgb(0 0 0 / 20%);
  -moz-border-radius: 16px;
  -webkit-border-radius: 16px;
  border-radius: 16px;
}

.clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

.disabled_link {
  pointer-events: none;
}

.overlay {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 99999999;
}

.bottom_bar{
  @include pc {
    margin-bottom: 24px;
  }
}
