.content_title {
  font-size: 16px;
  font-weight: bold;
  border-bottom: 1px solid #000;
  padding-bottom: 10px;
}

.content {
  margin-top: 20px;
}

.content_body {
  margin-top: 10px !important;
  white-space: pre-wrap;
}