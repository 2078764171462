@import "../utility.module.scss";

.info_container {
  @include sp {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
}

.info_article_card_list {
  @include sp {
    margin: 20px auto 0;
  }
  padding: 0 0;
  margin: 100px auto 0;
  max-width: 960px;
}

.info_article_card_list_title {
  text-align: center;
  padding-bottom: 16px;
  border-bottom: 1px solid var(--main-color);
  margin-bottom: 32px;
  color: #000;
  text-align: center;
  font-family: Noto Sans JP;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  @include sp {
    font-size: 16px;
  }
  @include pc {
    font-size: 20px;
  }
}

.info_article_card_list_article_list {
  @extend .clearfix;
}

.info_article_card_list_article {
  float: left;
  border-radius: 16px;
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.2);
  @include sp {
    width: 100%;
    margin-bottom: 15px;
  }
  @include pc {
    width: 310px;
    margin: 0 0 20px 10px;
  }
  background-color: #fff;
}
.info_article_card_list_article:hover {
  opacity: 0.65;
}

.info_article_card_list_more_btn {
  position: relative;
  width: 100%;
  max-width: 400px;
  margin-top: 28px;
  &::after {
    color: #f6f6f6;
    @extend .more_icon;
  }
  &:hover::after {
    @extend .more_icon;
  }
}

.info_article_card_list_more_link {
  color: #000;
  font-family: Noto Sans JP;
  text-decoration-line: underline;
  font-style: normal;
  line-height: normal;
  @include pc {
    font-size: 18px;
    font-weight: 500;
  }
  @include sp {
    font-size: 14px;
    font-weight: 400;
  }
}

.info_article_card_list_more {
  text-align: right;
}
.more_icon {
  content: "▼";
  font-size: 10px;
  position: absolute;
  top: -moz-calc(50% - 5px);
  top: -o-calc(50% - 5px);
  top: -webkit-calc(50% - 5px);
  top: calc(50% - 5px);
  right: 31px;
}
