@import "../utility.module.scss";

.member_entry_form {
  @include sp {
    padding: 30px 26px !important;
  }
  padding: 40px !important;
}

.member_entry_form_title {
  position: relative;
  padding-bottom: 24px;
  text-align: center;
  font-size: 23px;
  font-weight: bolder;
}

.member_entry_form_custom_message {
  margin: 0 auto;
  text-align: center;
  padding-bottom: 40px;
  font-size: 14px;
  line-height: 21px;
  color: #111111;
}

.member_entry_form_container {
  padding-bottom: 24px;
}

.member_entry_form_password_message {
  background: #F6F6F6;
  margin: 8px 0 0;
  padding: 8px;
  font-size: 12px;
}

.member_entry_form_password_message p {
  font-weight: 700;
  padding-bottom: 4px;
}

.member_entry_form_password_message ul {
  list-style: inside;
  padding-left: 4px;
}

.member_entry_card_agreement_label {
  width: 100%;
  display: inline-block;
  position: relative;
  padding-left: 26px;
  font-size: 12px;
  vertical-align: top;
  margin: 16px 0 8px 0;

  &:before {
    content: "";
    position: absolute;
    display: block;
    box-sizing: border-box;
    width: 18px;
    height: 18px;
    margin-top: -9px;
    left: 0;
    top: 50%;
    border: 1px solid #999;
    background-color: #fff;
    -webkit-border-radius: 50%;
    border-radius: 50%;
  }

  &:after {
    content: "";
    position: absolute;
    display: block;
    box-sizing: border-box;
    width: 10px;
    height: 8px;
    margin-top: -5px;
    top: 50%;
    left: 4px;
    transform: rotate(-45deg);
    border-bottom: 3px solid;
    border-left: 3px solid;
    border-color: #fff;
  }
}

.member_entry_card_agreement_text_link {
  text-decoration: underline;
}

// tod: 確認CLカスタムCSSの方でcheckboxがdisplay: noneになっているので、タブ押下時にフォーカスが移動しない
.member_entry_card_agreement_checkbox {
  opacity: 0;
  display: unset !important;
}

.member_entry_card_agreement_checkbox:focus + label:before {
  border: 2px solid #999;
}

.submit_container {
  width: 100%;
  max-width: 300px;
  position: relative;
  margin: 16px auto;
  padding: 0;
}

.submit_button:focus-within {
  border: 2px solid #999;
  -moz-border-radius: 60px;
  -webkit-border-radius: 60px;
  border-radius: 60px;
}

.agreement_checkbox:checked + label:before {
  background-color: var(--main-color);
}
