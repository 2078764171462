.drawer_menu {
  width: 100%;
  height: 100vh;
  left: 0;
  background-color: #fff;
  z-index: 100;
}

.logo {
  width: -moz-calc(100% - 24px);
  width: -o-calc(100% - 24px);
  width: -webkit-calc(100% - 24px);
  width: calc(100% - 24px);
  max-width: 120px;
}

.logo_new{
  width: 110px;
  height: 32px;
  margin-right: 8px;
  object-fit: contain;
}

.member_point_request_btn_header{
  position: relative;
  border-radius: 90px;
  border: 1px solid #CCC;
  width: calc(100% - 50px);
  padding: 17px 0;
  margin: 16px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  a{
    padding: 0;
    font-family: "Noto Sans JP";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
  }
  svg{
    position: absolute;
    top: 16px;
    right: 16px;
  }
}

.header {
  padding: 26px 25px;
  height: auto;
  position: absolute;
  display:block;
  top: 0;
  left: 0;
  right: 0;
  border-bottom: 1px solid #dddddd;
}

.header_new{
  padding: 26px 25px;
  height: auto;
  position: absolute;
  display:block;
  top: 0;
  left: 0;
  right: 0;
  p{
    color: #222;
    font-family: "Noto Sans JP";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
  }
}

.navi {
  position: absolute;
  top: 100px;
  left: 0;
  right: 0;
}