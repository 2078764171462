.text_area { 
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;

}