.sendbird-channel-preview__content__upper__header__total-members{
    display: none !important;
}

.sendbird-channel-header__title__right__user-id{
    display: none !important;
}

.sendbird-channel-preview__action{
    display: none !important;
    pointer-events: none;
}

.sendbird-modal{
    z-index: 999 !important;
}

.sendbird-edit-user-profile__theme{
    display: none !important;
    pointer-events: none !important;
}

.sendbird-channel-preview__content__lower__last-message{
    width: 100%;
    display: block;
    position: relative;
}

.sendbird-channel-preview__content__lower__unread-message-count{
    position: absolute;
    right: 5%;
}

.sendbird-message-status__icon{
    display: none !important;
}

.sendbird-icon-info{
    margin-left: 16px;
    cursor: pointer;
}

.sendbird-icon-search{
    cursor: pointer;
}

.sendbird-bottomsheet{
    z-index: 999;
}

.sendbird-message-input--textarea{
    line-height: 1 !important;
}

.sendbird-label{
    font-size: 16px !important;
}
.sendbird-conversation__footer{
    padding: 0px 0px 4px 0px !important;
}

#sendbird-message-input-text-field{
    height: fit-content !important;
    max-height: 250px !important;
}

.sendbird-icon-attach{
    position: absolute !important;
    top: 25% !important;
    right: 3% !important;
    cursor:  pointer !important;
}
@media (max-width: 768px) {
  .sendbird--mobile-mode .sendbird-og-message-item-body {
    max-width: 60vw;
  }
}
.sendbird-edit-user-profile__userid,
.sendbird-edit-user-profile__theme {
  display: none !important;
}

.sendbird-theme--light .sendbird-og-message-item-body .sendbird-og-message-item-body__description {
  border-top: 1px solid #ccc;
}
.sendbird-channel-preview
  .sendbird-channel-preview__content
  .sendbird-channel-preview__content__upper
  .sendbird-channel-preview__content__upper__header
  .sendbird-channel-preview__content__upper__header__channel-name {
  max-width: 200px;
}

.sendbird-message-input--textarea {
    max-height: 250px !important;
    min-height: 56px !important;
    overflow-y: auto !important;
    transition: height 0.2s ease !important;
}

.sendbird-message-input-wrapper__message-input{
    max-width: 100% !important;
}