@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap');

html,
body {
  height: 100%;
  // background-color: yellow;
}

body,
button {
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "Hiragino Sans", "ヒラギノ角ゴシック", "メイリオ", sans-serif;
  line-height: 1.5;
  color: #000000;
}

*, *:before, *:after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}