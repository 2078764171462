@import "../utility.module.scss";

.root {
  @include sp {
    padding-right: 0 !important;
    padding-left: 0 !important;
    margin-left: -4.2vw;
    margin-right: -4.2vw;
  }
}

.wrap {
  @include sp {
    margin: 20px auto 0;
  }
  max-width: 960px;
  margin: 32px auto 0;
  padding: 32px 0;
}

.wrap_new {
  @include sp {
    padding: 16px 0 102px 0px;
  }
  @include pc{
    padding: 88px 0 98px 0px;
  }
  max-width: 1440px;
  margin: 0 auto;
}

.recommend_contents {
  border-radius: 16px;
  @include sp {
    width: 100%;
    margin-bottom: 15px;
  }
  @include pc {
    height: 300px;
    margin-bottom: 27px;
  }
  background-color: #ffffff;
}

.recommend_contents_new {
  border-radius: 16px;
  @include sp {
    height: 100%;
    width: 100%;
    margin-bottom: 12px;
  }
  @include pc {
    height: 270px;
    margin-bottom: 27px;
  }
  background-color: #ffffff;
}

.title {
  position: relative;
  text-align: center;
  padding: 0 10px 16px 10px;
  margin-bottom: 40px;
  margin: 0 auto 32px;
  border-bottom: 1px solid var(--main-color);
  color: #000;
  text-align: center;
  font-family: Noto Sans JP;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  @include pc {
    font-size: 20px;
  }
  @include sp {
    font-size: 16px;
    margin-bottom: 16px;
  }
}

.swiper {
  position: relative;
}
