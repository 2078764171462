@import "../utility.module.scss";

.info_article_detail {
  @include sp {
    padding: 30px 18px;
  }
  width: 100%;
  max-width: 960px;
  padding: 40px;
  margin: 30px auto 0;
  word-wrap: break-word;
  @extend .card;
  img {
    max-width: 100%;
  }
}

.info_article_detail_new {
  @include sp {
    padding: 30px 18px;
  }
  @include pc {
    min-height: 604px;
  }
  width: 100%;
  max-width: 960px;
  padding: 40px;
  margin: 30px auto 0;
  word-wrap: break-word;
  @extend .card;
  img {
    max-width: 100%;
  }
}

.info_article_detail_title {
  position: relative;
  font-size: 22px;
  font-weight: bold;
  text-align: center;
  padding: 0 10px 38px 10px;
  margin-bottom: 40px;
  max-width: 700px;
  margin: 0 auto;
  &:after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 1px;
    bottom: 0;
    left: 0;
    background-color: #dddddd;
  }
}

.info_article_detail_eyecatch {
  text-align: center !important;
}

.info_article_detail_eyecatch_img {
  max-width: 100%;
  height: auto;
  margin: 30px 0;
}

.info_article_detail_meta {
  margin-bottom: 30px !important;
  text-align: right !important;
}

.category {
  text-decoration: underline;
}

.action {
  display: flex;
  align-items: end;
  justify-content: end;
}

.action_share_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 0;
  margin-top: -1px;
}

.action_share {
  font-size: 8px;
  color: #87867c;
  margin-top: 2px;
  line-height: 1;
}

.modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.modal_header {
  width: 100%;
  height: 60px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 10px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  border: 1px solid #ccc;
  @include sp {
    height: 40px;
  }
}
.close_button {
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 10px;
}
.paper {
  outline: none;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  margin-top: 60px;
  position: relative;
  overflow: visible;
  border: 1px solid #ccc;
  @include sp {
    margin-top: 40px;
  }
}
.zoom_container {
  width: 95vw;
  height: calc(95vh - 60px);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: visible;
  @include sp {
    height: calc(95vh - 40px);
  }
}
.image {
  width: 95vw;
  height: calc(95vh - 60px);
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  @include sp {
    height: "calc(95vh - 40px)";
  }
}
.clickable_image {
  cursor: pointer;
}

.navigation {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  padding: 1rem 4px;
  border-top: 1px solid #eee;
  background-color: #fff;

  &_prev,
  &_next {
    display: flex;
    flex-direction: column;
    text-decoration: none;
    max-width: 45%;

    &:hover {
      .navigation_title {
        text-decoration: underline;
      }
    }
  }

  &_label {
    font-size: 0.7rem;
    color: #666;
    margin-bottom: 0.5rem;
  }

  &_label_title {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  &_arrow {
    flex-shrink: 0;
    width: 16px;
    height: 16px;
  }

  &_title {
    color: #333333;
    font-size: 0.8rem;
    line-height: 1.4;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-decoration: underline;
  }

  &_next {
    text-align: right;
    margin-left: auto;
    
    .navigation_label_title {
      justify-content: flex-end;
    }
  }
}
