@import "../../../utility.module.scss";

.label {
  display: inline-block;
  width: 100%;
  color: #111111;
  font-size: 14px;
  font-weight: 700;
}

.required {
  padding-left: 4px;
  color: #EF2628;
  font-size: 12px;
  font-weight: 500;
}
