@import "../utility.module.scss";
.videoContainer {
  width: 100%;
  height: 100%;
  background-color: #fef2ef;
  @include pc {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    background-color: #fef2ef;
  }
}

.videoContent {
  background-color: #fef2ef;
}

.youtube_embed_container iframe {
  @include sp {
    width: 100% !important;
  }
  @include pc {
    width: 100% !important;
    height: 100vh !important;
  }
}
