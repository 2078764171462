@import "../utility.module.scss";

.root {
  @include sp {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  background: var(--sub-color);
}

.root_new{
  @include sp {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
}

.wrap {
  @include sp {
    margin: 20px auto 0;
  }
  max-width: 960px;
  margin: 32px auto 0;
  padding: 32px 0;
}
.title {
  position: relative;
  text-align: center;
  padding: 0 10px 16px 10px;
  margin-bottom: 40px;
  margin: 0 auto 32px;
  border-bottom: 1px solid var(--main-color);
  color: #000;
  text-align: center;
  font-family: Noto Sans JP;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  @include pc {
    font-size: 20px;
  }
  @include sp {
    font-size: 16px;
    margin-bottom: 16px;
  }
}
.swiper {
  position: relative;
}

.title_new{
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 16px;
  padding-bottom: 24px;
  h3{
    color: #222;
    font-family: "Noto Sans JP";
    @include pc{
      font-size: 36px;
    }
    @include sp{
      font-size: 24px;
    }
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
  }
}

.button_link{
  display: block;
  border-radius: 90px;
  border: 1px solid #CCC;
  text-align: center;
  margin-top: 4px;
  position: relative;
  padding: 17px 0;
  @include pc{
    width: 320px;
    margin: 24px auto 0 auto;
  }
  @include sp{
    width: 100%;
  }
  svg{
    position: absolute;
    top: calc(50% - 8px);
    right: 5px;
  }
  h3{
    color: #222;
    text-align: center;
    font-family: "Noto Sans JP";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
  }
}

.button_link:hover{
  opacity: 0.6;
}

.button_text{
  display: block;
  padding: 17px 0;
  width: 100%;
  height: 100%;
  color: #222;
  text-align: center;
  font-family: "Noto Sans JP";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
}