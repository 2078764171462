@import "../../utility.module.scss";

.form {
  @include sp {
    width: 50%;
  }
  margin-bottom: 0;
  padding: 13px 16px;
  width: 145px;
  height: 35px;
  font-size: 15px;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid #dddddd;
}

.disable {
  @extend .form;
  pointer-events: none;
  background: transparent;
  box-shadow: none;
  border-radius: unset;
}

.button {
  @include sp {
    width: 48%;
    line-height: 1;
  }

  display: inline-block;
  width: 145px;
  margin-left: 2%;
  vertical-align: top;

  height: 35px;
  & * {
    font-size: 12px;
    font-weight: bold;

    padding: 1px;
  }
}
