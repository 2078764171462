@import '../utility.module.scss';

.calendar { 
  display: block;
  margin-bottom: 15px;
  @include sp {
    width: 100%;

  }
}

.header_row { 
  display: flex;
  justify-content: space-between;
  
}

.header_day { 
  width: 14.28%;
  font-size: 12px;
  text-align: center;
  &:first-child {
    color: #FD3535;
  }
  &:last-child { 
    color: #3290FF;
  }
}

.body_row { 
  display: flex;
  justify-content: space-between;
}