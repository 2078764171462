.drawerMenuButton {
  position: absolute;
  top: -moz-calc(50% - 18px);
  top: -o-calc(50% - 18px);
  top: -webkit-calc(50% - 18px);
  top: calc(50% - 18px);
  right: 16px;
  width: 23px;
  height: 23px;
  &_new{
    position: absolute;
    top: -moz-calc(50% - 18px);
    top: -o-calc(50% - 18px);
    top: -webkit-calc(50% - 18px);
    top: calc(50% - 18px);
    right: 25px;
    width: 23px;
    height: 25px;
  }
}

