@import "../utility.module.scss";

.point_history_list {
  padding: 54px 0;
  max-width: 800px;
  margin: 0 auto;
}

.point_history_header {
  display: table;
  width: 100%;
}

.point_history_body {
  @include sp {
    -moz-border-radius: 0px;
    -webkit-border-radius: 0px;
    border-radius: 0px;
    margin-bottom: 6px;
  }

  display: block;
  width: 100%;
  margin-bottom: 10px;
  background-color: #fff;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  -moz-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0px 0px 8px 0px rgb(0 0 0 / 20%);
  box-shadow: 0px 0px 8px 0px rgb(0 0 0 / 20%);
}

.point_history_body_cell__header {
  white-space: pre-wrap;
}

.point_history_body_empty {
  border-radius: 5px;
  -moz-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0px 0px 8px 0px rgb(0 0 0 / 20%);
  box-shadow: 0px 0px 8px 0px rgb(0 0 0 / 20%);
  text-align: center;
  padding: 15px;
  background-color: #fff;
  font-weight: bold;
}

.table_body {
  display: table;
  width: 100%;
}

.point_history_body_row {
  display: table-row;
}

.point_history_body_cell_approval {
  @extend .point_history_body_cell;
  color: #ee0000;
}

.point_history_body_cell {
  @include sp {
    padding: 10px 10px 14px;
    width: 33%;
  }

  display: table-cell;
  text-align: center;
  width: 20%;
  padding: 20px 10px;
  font-weight: bold;
  line-height: 1;
  position: relative;
  &__header {
    width: 20%;
    display: table-cell;
    position: relative;
    padding: 20px 10px;
    font-weight: bold;
    text-align: center;
    @include sp {
      padding: 10px 10px 14px;
      width: 33%;
    }
  }

  &:last-child {
    &:after {
      content: none;
    }
  }

  &:after {
    content: "";
    display: block;
    position: absolute;
    width: 1px;
    height: 30%;
    top: 35%;
    right: 0;
    background-color: #dddddd;
  }
}

.body_date {
  padding: 14px 14px 0;
  font-size: 10px;
}

.action_month {
  width: 100px;
  display: inline-flex;
}

.entry_date {
  width: 120px;
  display: inline-flex;
}

.year_select {
  margin: 0 0 0 auto;
  width: 142px;
}

.selectBox {
  -moz-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0px 0px 8px 0px rgb(0 0 0 / 20%);
  box-shadow: 0px 0px 8px 0px rgb(0 0 0 / 20%);

  margin: 0 0 0 auto;
  width: 142px;
  border: 1px solid #dddddd;
  padding: 13px 16px;
}
