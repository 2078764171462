@import "../utility.module.scss";

.point_history_point_card {
  max-width: 800px;
  width: 100%;
  margin: 0 auto;
  background-color: #fff;
  margin-top: 40px;
  padding: 38px 40px 38px;
  -moz-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0px 0px 8px 0px rgb(0 0 0 / 20%);
  box-shadow: 0px 0px 8px 0px rgb(0 0 0 / 20%);
  -webkit-border-radius: 16px;
  border-radius: 16px;
}

.point_history_point_card_title {
  max-width: 490px;
  @include sp {
    padding-bottom: 30px;
  }

  position: relative;
  font-size: 22px;
  font-weight: bold;
  text-align: center;
  padding: 0 10px 38px 10px;
  margin-bottom: 25px;
  margin: 0 auto;
  &:after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 1px;
    bottom: 0;
    left: 0;
    background-color: #dddddd;
  }
}

.point_history_point_and_medal {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 490px;
  padding: 30px 0;
  margin: 10px auto 0 auto;
}

.point_history_point_card_point {
  width: 53%;
  position: relative;
}

.point_history_point_card_medal {
  display: flex;
  &:after {
    content: "";
    display: block;
    width: 1px;
    height: 70%;
    position: absolute;
    top: 15%;
    right: 0;
    background-color: #dddddd;
  }
}


.point_history_point_card_medal_txt {
  @include sp {
    font-size: 18px;
    padding: 20px 3px;
    width: -moz-calc(100% - 28px);
    width: -o-calc(100% - 28px);
    width: -webkit-calc(100% - 28px);
    width: calc(100% - 28px);
  }

  width: -moz-calc(100% - 44px);
  width: -o-calc(100% - 44px);
  width: -webkit-calc(100% - 44px);
  width: calc(100% - 44px);
  font-size: 23px;
  font-weight: bold;
  padding: 20px 3px;
  text-align: left;
}

.point_history_point_card_point_label {
  text-align: center;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
}

.point_history_point_card_point_label_point {
  text-align: center;
}

.point_history_point_card_point_txt {
  white-space: nowrap;
  @include sp {
    text-align: center;
    font-size: 25px;
    letter-spacing: normal;
  }
  width: 42%;
  text-align: right;
  font-size: 38px;
  font-weight: bold;
  margin-top: 11px;
}

// common
.clearfix {
  *zoom: 1;
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}
