@import "../../utility.module.scss";

.base {
  @include sp {
    width: 49%;
  }
  margin-bottom: 0;
  padding: 13px 16px;
  width: 145px;
  height: 35px;
  font-size: 14px;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid #dddddd;
}

.form_left {
  @extend .base;
}

.form_right {
  @extend .base;
  margin-left: 2%;
}

.disable {
  pointer-events: none;
  background: transparent;
  box-shadow: none;
  border-radius: unset;
}
