@import "../../utility.module.scss";

.container {
  display: inline-flex;
}

.icon {
  line-height: 18px;
}

.information {
  display: inline-block;
  padding-left: 4px;
  line-height: 14px;
  color: #111111;
  font-size: 12px;
  font-weight: 500;
}
