.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4px 0;
  cursor: pointer;
  gap: 4px;
  min-width: 40px;
}

.btn {
  display: flex;
  align-items: center;
  text-align: center;
}

.icon_container {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  width: 45px;
  height: 24px;
  margin: auto -8px;
}

.counter {
  font-size: 14px;
  color: #87867c;
  display: flex;
  align-items: center;
  height: 24px;
}

.action_label {
  font-size: 8px;
  color: #87867c;
  line-height: 1;
  margin-top: 2px;
  position: relative;
  font-weight: bold;
}