@import '../utility.module.scss';

.primary { 
  background-color: var(--main-color);
  border: 2px solid var(--main-color);
  @extend .common;
  & * {
    color: #fff;
  }
  &:hover{
    opacity: 0.6;
  }
}

.disable { 
  border: 2px solid #DDDDDD;
  @extend .common;
  background-color: #DDDDDD;
  & * {
    color: #fff;
  }
}

.secondary { 
  @extend .common;
  background-color: #fff;
}

.tertiary { 
  @extend .common;
  border: 2px solid #DDDDDD;
  & * {
    color: #888888;
  }
  background-color: #ffffff;
  &:hover { 
    background-color: #F6F6F6;
  }
}

.common {
  @include pc{
    &:hover {
      -moz-transition: all 0.2s ease-in-out;
      -o-transition: all 0.2s ease-in-out;
      -webkit-transition: all 0.2s ease-in-out;
      transition: all 0.2s ease-in-out;
      -moz-border-radius: 60px;
      -webkit-border-radius: 60px;
      border-radius: 60px;
    }
  }
  display: block;
  text-decoration: none;
  text-align: center;
  position: relative;
  margin: 0 auto;
  padding: 0;
  -moz-border-radius: 60px;
  -webkit-border-radius: 60px;
  border-radius: 60px;
  & * {
    font-size: 18px;
    font-weight: 500;
    text-decoration: none;
    display: inline-block;
    padding: 10px 10px;
    width: 100%;
  }
}
