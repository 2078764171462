@import "../utility.module.scss";

.title {
  position: relative;
  padding-bottom: 36px;
  margin-bottom: 39px;
  text-align: center;
  font-size: 23px;
  font-weight: bolder;
  &::after {
    content: "";
    display: block;
    width: 100%;
    height: 2px;
    background-color: #ddd;
    position: absolute;
    bottom: 0;
    left: 0;
  }
}

.table {
  margin-top: 14px;
  width: 100%;
  & tr {
    @include sp {
      display: block;
    }
  }
  & th {
    @include sp {
      padding: 0 0 8px;
      width: 100%;
      text-align: left;
      display: block;
    }
    width: 19%;
    text-align: right;
    padding: 0 17px 15px 0;
    font-size: 14px;
    line-height: 1.2;
    vertical-align: middle;
  }
  & td {
    @include sp {
      width: 100%;
      display: block;
    }
    width: 80%;
    padding: 0 0 15px 0;
    font-size: 0;
    vertical-align: middle;
  }
}

.input_password {
  padding: 13px 16px;
  width: 100%;
  font-size: 15px;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid #dddddd;
}

.from_btn {
  margin-top: 30px !important;
  text-align: center !important;
  display: block;
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
}

.error_message {
  margin: 20px 0;
  color: #f00;
  font-weight: bold;
  font-size: 16px;
}
