@import "../utility.module.scss";

.title {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
  padding-top: 15px;
}

.step_container {
  position: relative;
  width: 100%;
}
.step_content {
  position: absolute;
  top: 0;
  left: 0;
}

.discription {
  @include sp {
    font-size: 12px;
  }
  font-size: 14px;
  text-align: center;
}

.event_tiile {
  background-color: #f6f6f6;
  border-radius: 5px;
  padding: 10px;
  margin: 12% 0 10px;
}

.event_tiile_inner {
  @include sp {
    font-size: 12px;
    width: 100%;
  }
  width: 68%;
  font-size: 14px;
  margin-left: auto;
}

.form_title {
  position: relative;
  padding-bottom: 36px;
  margin-bottom: 39px;
  text-align: center;
  font-size: 23px;
  font-weight: bolder;
  &:after {
    content: "";
    display: block;
    width: 100%;
    height: 2px;
    background-color: #ddd;
    position: absolute;
    bottom: 0;
    left: 0;
  }
}
.form_table {
  margin: 14px auto;
  max-width: 100%;

  & th {
    @include sp {
      display: block;
      padding: 0 0 8px;
      width: 100%;
      text-align: left;
      font-size: 14px;
    }
    width: 41%;
    text-align: right;
    padding: 0 17px 15px 0;
    font-size: 14px;
    vertical-align: middle;
    line-height: 1.2;
  }
  & td {
    @include sp {
      padding-right: 0;
      display: block;
      padding: 0 0 20px 0;
      width: 100%;
    }
    width: 300px;
    padding: 0 0 20px 0;
    vertical-align: middle;
  }
}

.button_list {
  @include sp {
    display: block;
    padding: 0 5px 20px;
  }
  display: flex;
  margin-top: 30px;
  padding: 0 250px;
}

.button {
  @include sp {
    width: 100%;
    height: 50px;
    margin-top: 10px;
  }
  width: 150px;
  height: 40px;

  & * {
    font-size: 14px;
    font-weight: bold;
  }
}

.button_disable {
  @include sp {
    margin-top: 10px;
  }

  background-color: #dddddd;
  border: 1px solid #dddddd;
  display: block;
  text-decoration: none;
  text-align: center;
  position: relative;
  margin: 0 auto;
  padding: 0;
  -moz-border-radius: 60px;
  -webkit-border-radius: 60px;
  border-radius: 60px;
  & * {
    color: #ffffff;
    font-size: 14px;
    font-weight: bold;
    text-decoration: none;
    display: inline-block;
    padding: 10px 10px;
    width: 100%;
  }
}

.agreement {
  text-align: center;
}

.agreement_checkbox {
  display: none;
}

.event_form_checkbox:checked + label:before {
    background-color: var(--main-color) !important;
}

.agreement_label {
  display: inline-block;
  position: relative;
  padding-left: 25px;
  padding-right: 10px;
  text-align: center;
  font-size: 12px;
  vertical-align: top;

  &:before {
    content: "";
    position: absolute;
    display: block;
    box-sizing: border-box;
    width: 18px;
    height: 18px;
    margin-top: -9px;
    left: 0;
    top: 50%;
    border: 1px solid #999;
    background-color: #fff;
    -webkit-border-radius: 50%;
    border-radius: 50%;
  }

  &:after {
    content: "";
    position: absolute;
    display: block;
    box-sizing: border-box;
    width: 10px;
    height: 8px;

    @include pc {
      margin-top: -11px;
    }
    @include sp {
      margin-top: -2px;
    }
    top: 15px;
    left: 4px;
    transform: rotate(-45deg);
    border-bottom: 3px solid;
    border-left: 3px solid;
    border-color: #fff;
  }
}

.agreement_text_link {
  text-decoration: underline;
}

.post_code_btn {
  @include sp {
    line-height: 1.2;
  }
  @include pc {
    padding-top: 6px;
  }
}

.second_step_content path:nth-child(2) {
  fill: var(--main-color);
}
