.paper {
  overflow: initial,
}

.detailButton {
  font-weight: bold;
  border-radius: 100px;
  margin: auto;
  width: 200px;
  font-size: 16px;
  padding: 12px 50px;
}

.text {
  width: 300px;
  font-size: 14px;
  padding: 0 32px;
}

.titleContainer {
  position: relative;
  display: flex;
  align-items: center;
  width: 300px;
  margin: 26px auto 0 auto;
}

.title {
  font-weight: 700;
  width: 200px;
  margin: 16px auto auto auto;
  text-align: center;
  white-space: pre-wrap;
}

.closeIcon {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 4px;
  right: 4px;
}

.scratchPng {
  width: 180px;
  height: 180px;
}

.ribbonTitle {
  font-weight: 700;
  font-size: 16px;
  padding: 3px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 55px;
  margin: auto;
}

.closeButton {
  &:hover {
    background: none;
  }
}

.scratchBody {
  padding: 0 32px 32px 32px;
}

.linkStyle {
  color: #006CC0;
  font-weight: 700;
}