@import "../utility.module.scss";

.component {
  width: 180px;
  height: 360px;
  @include sp {
    width: 144px;
    height: 288px;
  }
}

.container {
  position: relative;
  width: 100%;
  height: 88%;
  overflow: hidden;
  background-color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.component_new{
  width: 100%;
}

.container_new{
  margin: 2px 0 0 2px;
  box-sizing: border-box;
  height: 184px;
  @include pc{
    width: 184px;
    iframe{
      height: 315px;
      width: 560px;
    }
  }
  @include sp{
    width: 184px;
    div:first-child{
      width: 100% !important;
    }
    iframe{
      height: 315px;
      width: 560px;
    }
    blockquote{
      iframe{
        margin-top: 22px !important;
        height: 700px !important;
      }
    }
  }
  position: relative;
  background-color: #FFF;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.20);
  border-radius: 16px;
  overflow: hidden;
  .tiktok-embed{
    width: 100%;
    height: 100%;
  }
}

.container_new:hover{
  cursor: pointer;
  opacity: 0.6;
}

.title_new{
  margin-top: 12px;
  color: #222;
  font-family: "Noto Sans JP";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  width: 180px;
  max-height: 60px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden !important;
}

.title {
  padding: 0 8px;
  margin-bottom: 12px;
  flex-shrink: 0;
  color: #000;
  font: {
    family: Noto Sans JP;
    size: 14px;
    style: normal;
    weight: 700;
  }
  line-height: 1.5em;
  max-height: 3em;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  @include sp {
    font: {
      family: Noto Sans JP;
      size: 10px;
      style: normal;
      weight: 700;
    }
  }
}
.youtube_embed iframe {
  position: absolute;
  top: 0;
  left: 0;
  // width: 100% !important;
  // height: 100% !important;
  width: 560px !important;
  height: 315px I !important;
  object-fit: contain;
}

.instagram iframe{
  height: 524px;
}
