@import "../utility.module.scss";

.login_form {
  width: 100%;
  max-width: 550px;
  padding: 40px;
  margin: 36px auto 0;
  @extend .card;
}

.login_form_input {
  appearance: none;
  font: inherit;
  margin-top: 14px;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid #dddddd;
  width: 100%;
  padding: 12px 16px;
  background-color: #fff;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  -moz-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0px 0px 8px 0px rgb(0 0 0 / 20%);
  box-shadow: 0px 0px 8px 0px rgb(0 0 0 / 20%);
  line-height: normal;
}

.login_form_password_reset_contain {
  margin-top: 14px;
}

.login_form_password_reset {
  text-align: right;
  line-height: 1;
  padding-top: 1px;
}
.login_form_password_reset_link {
  font-size: 13px;
  letter-spacing: 1px;
  color: #000;
  text-decoration: underline;
}

.login_form_password_save {
  text-align: center;
  margin-top: 33px;
}

.login_form_password_save_checkbox {
  display: none;
  &:checked + label:before {
    background-color: var(--main-color) !important;
  }
}

.login_form_password_save_label {
  display: inline-block;
  position: relative;
  padding-left: 25px;
  padding-right: 10px;
  text-align: center;
  margin-bottom: 16px;

  &:before {
    content: "";
    position: absolute;
    display: block;
    box-sizing: border-box;
    width: 18px;
    height: 18px;
    margin-top: -10px;
    left: 0;
    top: 50%;
    border: 1px solid #999;
    background-color: #fff;
    -webkit-border-radius: 50%;
    border-radius: 50%;
  }

  &:after {
    content: "";
    position: absolute;
    display: block;
    box-sizing: border-box;
    width: 10px;
    height: 8px;
    margin-top: -9px;
    top: 15px;
    left: 4px;
    transform: rotate(-45deg);
    border-bottom: 3px solid;
    border-left: 3px solid;
    border-color: #fff;
  }
}

.login_form_apply_btn {
  width: 100%;
  max-width: 300px;
  button {
    font-weight: bold;
  }
}

.login_form_add_account_message {
  text-align: center;
  margin-top: 22px;
  margin-bottom: 16px;
}

.error_message,
.email_error_message {
  color: #f00;
  font-weight: bold;
}

.error_message {
  margin: 20px 0;
  font-size: 16px;
}

.email_error_message {
  font-size: 12px;
}

.container {
  display: inline-block;
  width: 100%;
}

.icon_conatainer {
  position: relative;
}

.icon {
  position: absolute;
  cursor: pointer;
  top: -25px;
  right: 10px;
  font-size: 15px;
  transform: translateY(-50%);
}

.install_modal_container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.install_container {
  @include pc {
    width: 500px;
    height: auto;
    border-radius: 12px;
  }
  @include sp {
    max-height: calc(100vh - 165px);
    overflow-y: hidden;
    scrollbar-width: none;
    -ms-overflow-style: none;
    width: calc(100vw - 40px);
    ::-webkit-scrollbar {
      display: none;
    }
  }
  border-radius: 12px;
  background-color: #fff;
}

.install_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
}

.close_button {
  display: flex;
  align-items: center;
  :hover {
    cursor: pointer;
    opacity: 0.6;
  }
  svg {
    fill: #fff;
  }
  position: absolute;
  top: -14px;
  right: -14px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #999;
}

.install_common {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 12px;
  background-color: #fff;
  border-top-left-radius: 12px;
}

.app_icon {
  margin-right: 16px;
  width: 56px;
  height: 56px;
  border-radius: 8px;
  object-fit: contain;
}

.install_text {
  white-space: pre-line;
  font-size: 13px;
}

.install_instruction {
  padding: 8px;
  border-radius: 8px;
  @include sp {
    max-height: calc(100vh - 260px);
    overflow: scroll;
  }
}

.install_instruction_container {
  background-color: #fff;
  width: 95%;
  margin: 0 auto;
  border-radius: 8px;
}

.install_step {
  display: flex;
  gap: 5px;
  align-items: flex-start;
  font-size: 13px;
  padding: 8px;
}

.install_image_1 {
  width: 80%;
  height: 100%;
  object-fit: contain;
  margin: 0 auto;
  display: block;
}

.install_step_container {
  margin-top: 16px;
}

.install_button_container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #fff;
  padding-bottom: 12px;
}

.install_button {
  background-color: var(--main-color) !important;
  color: #fff;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  border-radius: 90px;
  padding: 10px 24px 10px 24px;
  margin-top: 20px;
}

.install_button:hover {
  cursor: pointer;
  opacity: 0.6;
}

.install_image_2 {
  width: 80%;
  height: 200px;
  object-fit: contain;
  margin: 0 auto;
  display: block;
}

.step_number {
  display: flex;
  justify-content: center;
  background-color: #999;
  color: #fff;
  border-radius: 100%;
  width: 18px;
  min-width: 18px;
  height: 18px;
}

.install_step_text {
  font-weight: bold;
}

.install_image_step1 {
  width: 40%;
  height: auto;
  object-fit: contain;
  display: block;
  margin-left:30px;
}
